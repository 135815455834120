import {Box, CircularProgress, Container, IconButton, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ArrowBackIosNew} from "@mui/icons-material";
import {useEffect, useState} from "react";
import Progress from "../Common/Progress";

const NewsItem = ({ getNewsItem, newsItem, newsItemLoading }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (id !== null) {
            getNewsItem(id)
        }
    }, [id])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const [file, setFile] = useState('')
    useEffect(() => {
        if (newsItem) {
            let item = newsItem.files.find(x => x.isMain == true)
            if (item != null) setFile(item.id)
        }
    }, [newsItem])

    return (
        <Container>
            <Box sx={{ cursor: 'pointer' }} mb={3} onClick={() => navigate(-1)} display={'flex'} alignItems={'center'}>
                <IconButton color={'primary'}>
                    <ArrowBackIosNew/>
                </IconButton>
                <Typography variant={'h5'}>Назад</Typography>
            </Box>
            <Box>
                {
                    newsItemLoading ? <Progress/> :
                        newsItem !== null &&
                        <Box>
                            <Typography textAlign={'center'}>{new Date(Date.parse(newsItem.createdAt)).toLocaleDateString()}</Typography>
                            <Box mt={1}>
                                <Typography textAlign={'center'} variant={'h4'}>{newsItem.title}</Typography>
                            </Box>
                            <Box m={3} flexDirection={'column'} display={{ xs: 'flex', sm: 'flex', md: 'none' }} justifyContent={'center'} alignItems={'center'}>
                                <Box>
                                    { file !== '' && <img width={'100%'} style={{ borderRadius: '15px', maxHeight: '50%' }} src={`https://yuku.kz/Images?fileId=${file}`}/> }
                                </Box>
                                {
                                    newsItem.files.filter(x => x.isMain === false).map(x =>
                                        <Box mt={2}>
                                            <img width={'100%'} style={{ borderRadius: '15px', maxHeight: '50%' }} src={`https://yuku.kz/Images?fileId=${x.id}`}/>
                                        </Box>
                                    )
                                }
                            </Box>
                            <Box m={3} display={{ xs: 'none', sm: 'none', md: 'flex' }} justifyContent={'center'} alignItems={'center'}>
                                <Box>
                                    { file !== '' && <img width={400} style={{ borderRadius: '15px', maxHeight: '50%' }} src={`https://yuku.kz/Images?fileId=${file}`}/> }
                                </Box>
                                {
                                    newsItem.files.filter(x => x.isMain === false).map(x =>
                                        <Box>
                                            <img width={400} style={{ borderRadius: '15px', maxHeight: '50%', marginLeft: '15px' }} src={`https://yuku.kz/Images?fileId=${x.id}`}/>
                                        </Box>
                                    )
                                }
                            </Box>
                            <Box mt={1} p={{ xs: 1, sm: 2, md: 4 }}>
                                <Typography align={'justify'} sx={{ whiteSpace: 'pre-wrap' }}>{newsItem.description}</Typography>
                            </Box>
                        </Box>
                }
            </Box>
        </Container>
    )
}

export default NewsItem;