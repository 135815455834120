import {
    AppBar,
    Box,
    Button,
    Container,
    Divider,
    IconButton,
    MenuItem,
    Tab,
    Tabs,
    Toolbar,
    Typography
} from "@mui/material";
import logo from '../../images/EVS-logo-aligned.png'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {useEffect, useState} from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import Language from "../Language/Language";
import {handleChangeLanguage} from "../../redux/language-reducer";
import LanguageContainer from "../Language/LanguageContainer";
import {useLocation, useNavigate} from "react-router-dom";
import {links} from "../../common/links";
import {Close} from "@mui/icons-material";


const Header = ({ languageValues }) => {
    const [tabValue, setTabValue] = useState(0)
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const navigate = useNavigate();
    const [menuOpened, setMenuOpened] = useState(false)
    const location = useLocation();
    const handleChangeOpened = () => {
        setMenuOpened(!menuOpened)
    }
    const handleGoToLink = (link) => {
        navigate(link)
        setMenuOpened(false)
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };
    const openLink = (url) => {
        window.open(url);
    };
    return (
        <>
            <AppBar elevation={0} sx={{ display: { xs: 'none', sm: 'block' }, backgroundColor: 'white' }}>
                <Container>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box sx={{ width: '200px' }}>
                            <img style={{ width: '120px' }} src={logo}/>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Box p={1} display={'flex'} justifyContent={'flex-end'}>
                                <Box ml={3}>
                                    <Button onClick={() => openInNewTab('https://www.instagram.com/evs.kz/')}>
                                        <InstagramIcon sx={{ marginRight: '5px', color: '#70A366' }}/>
                                        <Typography sx={{ color: 'black', textTransform: 'lowerCase' }}>evs.kz</Typography>
                                    </Button>
                                </Box>
                                <Box ml={3}>
                                    <a href="tel:+77714277000">
                                        <Button>
                                            <PhoneIphoneIcon sx={{ marginRight: '5px', color: '#70A366' }}/>
                                            <Typography sx={{ color: 'black', textTransform: 'capitalize' }}>+77714277000</Typography>
                                        </Button>
                                    </a>
                                </Box>
                                <Box ml={3}>
                                    <a href="tel:+77273783293">
                                        <Button>
                                            <LocalPhoneIcon sx={{ marginRight: '5px', color: '#70A366' }}/>
                                            <Typography sx={{ color: 'black', textTransform: 'capitalize' }}>+77273783293</Typography>
                                        </Button>
                                    </a>
                                </Box>
                                <Box ml={3}>
                                    <a href="mailto:info@evs.kz">
                                        <Button>
                                            <EmailIcon sx={{ marginRight: '5px', color: '#70A366' }}/>
                                            <Typography sx={{ color: 'black', textTransform: 'lowerCase' }}>info@evs.kz</Typography>
                                        </Button>
                                    </a>
                                </Box>
                                <Box ml={3}>
                                    <Button onClick={() => openInNewTab('https://wa.me/+77714277000?text=Добрый день! Нашел вас на evs.kz')}>
                                        <WhatsAppIcon sx={{ marginRight: '5px', color: '#70A366' }}/>
                                        <Typography sx={{ color: 'black', textTransform: 'capitalize' }}>WhatsApp</Typography>
                                    </Button>
                                </Box>
                            </Box>
                            <Divider/>
                            <Box p={1} display={{ sm: 'none', md: 'flex' }} alignItems={'center'} justifyContent={'flex-end'}>
                                <Tabs value={location.pathname} indicatorColor={'primary'}>
                                    {
                                        links.map(
                                            (x, index) => <Tab onClick={() => navigate(x.link)} value={x.link} label={<Typography>{languageValues[x.title]}</Typography>}></Tab>
                                        )
                                    }
                                </Tabs>
                                <LanguageContainer/>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </AppBar>
            <AppBar elevation={0} sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, backgroundColor: 'white' }}>
                <Toolbar sx={{ height: '100px' }}>
                    <Box display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box sx={{ width: '300px'}}>
                            <img style={{ width: '120px' }} src={logo}/>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                            <a href="tel:+77714277000">
                                <IconButton color={'secondary'}>
                                    <LocalPhoneIcon/>
                                </IconButton>
                            </a>
                            <IconButton  onClick={() => openInNewTab('https://wa.me/+77714277000?text=Добрый день! Нашел вас на evs.kz')} color={'primary'}>
                                <WhatsAppIcon/>
                            </IconButton>
                            <LanguageContainer/>
                            <IconButton onClick={handleChangeOpened}>
                                <MenuIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>
                <Box display={menuOpened ? 'block' : 'none'}>
                    <Box sx={{ }}>
                        <Box>
                            {
                                links.map(
                                    (x, index) => <Box>
                                        <MenuItem divider sx={{ margin: 0, padding: 0, justifyContent: 'center' }} onClick={() => handleGoToLink(x.link)}>
                                            <Typography color={location.pathname === x.link ? 'primary' : 'inherit'} textAlign={'center'} sx={{ fontWeight: location.pathname === x.link ? 'bold' : 'regular' }}>{languageValues[x.title]}</Typography>
                                        </MenuItem>
                                    </Box>
                                )
                            }
                            <MenuItem onClick={() => setMenuOpened(false)} sx={{ justifyContent: 'center' }}>
                                <Close/>
                            </MenuItem>
                        </Box>
                    </Box>
                </Box>
            </AppBar>
        </>
    )
}

export default Header;