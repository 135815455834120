import {Box, Button, Container, Typography} from "@mui/material";
import EvStationIcon from "@mui/icons-material/EvStation";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import BoltIcon from "@mui/icons-material/Bolt";
import HomeIcon from "@mui/icons-material/Home";

const OurServices = ({ languageValues, handleOpenSendRequest }) => {
    return (
        <Container>
            <Box mt={3} display={'flex'} flexDirection={{ xs: 'column', sm: 'column', md: 'row' }} flexGrow={1} justifyContent={'space-between'}>
                <Box pt={{ xs: 5, sm: 5, md: 8 }} pb={ { xs: 7, sm: 7, md: 14 } } mr={{ md: 2, sm: 0 }} display={'flex'} boxSizing={'border-box'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} width={{ sm: '100%', md: '50%' }} sx={{ borderRadius: '15px', marginBottom: '20px', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                    <Typography variant={'h4'} sx={{ fontWeight: 'bold', color: 'white' }}>{languageValues?.ourServices?.title}</Typography>
                    <Typography textAlign={'center'} sx={{ color: 'rgba(255, 255, 255, 0.8)', marginTop: '15px', padding: '20px'  }}>
                        {languageValues?.ourServices?.description}
                    </Typography>
                    <Box mt={3}>
                        <Button onClick={() => { handleOpenSendRequest() }} sx={{ background: 'linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(10, 157, 121, 1) 49%, rgba(10, 157, 72, 1) 100%)', color: 'white', '&:hover': { background: 'linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(10, 157, 121, 1) 49%, rgba(10, 157, 72, 1) 100%)' } }}><strong>Отправить запрос</strong></Button>
                    </Box>
                </Box>
                <Box flexGrow={2} display={'flex'} flexWrap={'wrap'} width={'100%'} justifyContent={'space-between'}>
                    {
                        [
                            {
                                icon: <EvStationIcon sx={{ color: 'green', width: '70px', height: '70px' }}/>,
                                title: languageValues?.ourServices?.network?.title,
                                description: languageValues?.ourServices?.network?.description,
                            },
                            {
                                icon: <PhoneIphoneIcon sx={{ color: 'green', width: '70px', height: '70px' }}/>,
                                title: languageValues?.ourServices?.mobileApp?.title,
                                description: languageValues?.ourServices?.mobileApp?.description,
                            },
                            {
                                icon: <BoltIcon sx={{ color: 'green', width: '70px', height: '70px' }}/>,
                                title: languageValues?.ourServices?.supply?.title,
                                description: languageValues?.ourServices?.supply?.description,
                            },
                            {
                                icon: <HomeIcon sx={{ color: 'green', width: '70px', height: '70px' }}/>,
                                title: languageValues?.ourServices?.home?.title,
                                description: languageValues?.ourServices?.home?.description,
                            }
                        ].map(x =>
                            <Box p={2} width={{ xs: '100%', sm: '100%' }} maxWidth={{ lg: '44%', md: '43%' }} sx={{ marginBottom: '20px', borderRadius: '15px', background: 'linear-gradient(135deg, rgba(0, 0, 0, 0.04), #EFF2ED)' }}>
                                <Box display={'flex'} justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start' }}>{x.icon}</Box>
                                <Typography textAlign={{ xs: 'center', md: 'left' }} variant={'h5'} fontWeight={'bold'} sx={{ marginTop: '10px', color: 'rgba(0, 0, 0, 0.8)' }}>{x.title}</Typography>
                                <Typography textAlign={{ xs: 'center', md: 'left' }} sx={{ marginTop: '15px', color: 'rgba(0, 0, 0, 0.6)' }}>{x.description}</Typography>
                            </Box>
                        )
                    }
                </Box>
            </Box>
        </Container>
    )
}

export default OurServices;