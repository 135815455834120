import {connect} from "react-redux";
import React from 'react';
import WhyChooseUs from "./WhyChooseUs";

const WhyChooseUsContainer = React.memo((props) => {
    return (
        <WhyChooseUs { ...props } />
    )
})

const mapStateToProps = (state) => {
    return {

    };
}


export default connect(mapStateToProps, { })(WhyChooseUsContainer);
