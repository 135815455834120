import {connect} from "react-redux";
import React from 'react';
import BecomePartner from "./BecomePartner";
import {sendRequest} from "../../redux/requests-reducer";

const BecomePartnerContainer = React.memo((props) => {
    return (
        <BecomePartner { ...props } />
    )
})

const mapStateToProps = (state) => {
    return {

    };
}


export default connect(mapStateToProps, { sendRequest })(BecomePartnerContainer);