import {Box, CircularProgress, Typography} from "@mui/material";


const Progress = () => {
    return (
        <Box sx={{
            width: '100%',
            marginTop: '200px',
            marginBottom: '200px',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center'
        }}>
            <CircularProgress/>
        </Box>
    )
}

export default Progress;