import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    TextField
} from "@mui/material";
import {Close} from "@mui/icons-material";
import {useEffect, useState} from "react";

const SendRequestDialog = ({ open, handleClose, sendRequest, partnership }) => {

    const [firstName, setFirstName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [type, setType] = useState(0)
    const [disabled, setDisabled] = useState(false)

    const types = [
        { value: 0, title: 'Отзыв' },
        { value: 1, title: 'Жалоба' },
        { value: 2, title: 'Рекомендация' },
        { value: 3, title: 'Заявка на возврат' },
        { value: 4, title: 'Запрос на сотрудничество' },

    ]



    const resetForm = () => {
        setFirstName('')
        setSurname('')
        setEmail('')
        setPhone('')
        setMessage('')
        setType(0)
    }

    const handleSend = async () => {
        setDisabled(true)
        await sendRequest({
            firstName,
            surname,
            phone,
            message,
            email,
            requestType: type
        })
        setDisabled(false)
        handleClose();
        alert('Спасибо за обращение! В скором времени мы обработаем Ваш запрос')
    }

    useEffect(() => {
        if (open === false) resetForm();
    }, [open])


    useEffect(() => {
        if (partnership === true) setType(4)
    }, [partnership])

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <DialogTitle>Новый запрос</DialogTitle>
                <IconButton onClick={handleClose} sx={{ marginRight: '15px' }}>
                    <Close/>
                </IconButton>
            </Box>
            <DialogContent>
                <TextField onChange={(event) => setType(event.target.value)} value={type} sx={{ marginBottom: '10px' }} label={'Тип запроса'} select variant={'outlined'} fullWidth>
                    {types.map(x =>
                        <MenuItem value={x.value}>{x.title}</MenuItem>
                    )}
                </TextField>
                <TextField value={firstName} onChange={event => setFirstName(event.target.value)} sx={{ marginBottom: '10px' }} label={'Имя'} variant={'outlined'} fullWidth/>
                <TextField value={surname} onChange={event => setSurname(event.target.value)} sx={{ marginBottom: '10px' }} label={'Фамилия'} variant={'outlined'} fullWidth/>
                <TextField value={email} onChange={event => setEmail(event.target.value)} required sx={{ marginBottom: '10px' }} label={'E-mail'} variant={'outlined'} fullWidth/>
                <TextField value={phone} onChange={event => setPhone(event.target.value)} sx={{ marginBottom: '10px' }} label={'Телефон'} variant={'outlined'} fullWidth/>
                <TextField value={message} onChange={event => setMessage(event.target.value)} sx={{ marginBottom: '10px' }} label={'Сообщение'} multiline rows={4} variant={'outlined'} fullWidth/>
            </DialogContent>
            <DialogActions>
                <Button onClick={resetForm} variant={'outlined'}>Сброс</Button>
                <Button disabled={disabled} onClick={handleSend} sx={{ color: 'white' }} variant={'contained'}>Отправить</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SendRequestDialog;