import {Box, Container, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography} from "@mui/material";
import HandshakeIcon from "@mui/icons-material/Handshake";
import dss from '../../images/partners/dss.png'
import khorgos from '../../images/partners/khorgos.png'
import oiqaragai from '../../images/partners/oiqaragai.jpg'
import ramstoreallin from '../../images/partners/ramstoreallin.png'
import rp from '../../images/partners/rp.png'
import rubai from '../../images/partners/rubai.png'
import sadu from '../../images/partners/sadu.png'
import samal from '../../images/partners/samal.png'
import shymbulak from '../../images/partners/shymbulak.png'
import everest from '../../images/partners/everest.jpg'

const Partners = ({ languageValues }) => {
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };
    const values = [
        {
            img: sadu,
            title: 'Отель SADU',
            link: 'https://www.instagram.com/saduhotel_almaty/'
        },
        {
            img: ramstoreallin,
            title: 'МФК Ramstore ALL-In',
            link: 'https://ramstore.kz/'
        },
        {
            img: shymbulak,
            title: 'Shymbulak Mountain Resort',
            link: 'https://shymbulak.com/'
        },
        {
            img: oiqaragai,
            title: 'Лесная Сказка',
            link: 'https://www.instagram.com/oiqaragai/'
        },
        {
            img: rp,
            title: 'Royal-Petrol',
            link: 'https://royal-petrol.kz/'
        },
        {
            img: dss,
            title: 'Дирекция спортивных сооружений города Алматы',
            link: 'https://dss-almaty.kz/'
        },
        {
            img: rubai,
            title: 'Ресторан национальной и восточной кухни RUBAI',
            link: 'https://2gis.kz/astana/firm/70000001079128844'
        },
        {
            img: samal,
            title: 'Samal Resort & SPA',
            link: 'https://www.instagram.com/samal_resort_and_spa/'
        },
        {
            img: khorgos,
            title: 'СЭЗ "Хоргос - Восточные ворота"',
            link: 'https://sez.qazindustry.gov.kz/ru/company/7b4a59b4-c171-416d-802b-3c7a5bcff005'
        },
        {
            img: everest,
            title: 'EVEREST DEVELOPMENT',
            link: 'https://www.instagram.com/everest_development_kz/'
        }
    ]

    function srcset(image, size, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${
                size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    return (
        <Container>
            <Box mt={2}>
                <Box mb={2} sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                    <HandshakeIcon sx={{ width: { xs: '30px', sm: '50px' }, height: { xs: '30px', sm: '50px' } }}/>
                    <Typography sx={{ marginLeft: '10px', fontSize: { xs: '24px' } }} variant={'h4'}>{languageValues?.partners}</Typography>
                </Box>
                <Box flexWrap={'wrap'} sx={{ borderRadius: '15px' }} display={{ xs: 'flex', md: 'none' }} justifyContent={'space-around'}>
                    {
                        values.map(x =>
                            <Box onClick={() => openInNewTab(x.link)} pt={2} sx={{ cursor: 'pointer', '&:hover': { boxShadow: '0px 0px 20px 20px rgba(46,164,57,0.1)' }, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)', width: { xs: '160px', sm: '200px' }, borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                <img style={{ height: '80px', objectFit: 'cover', borderRadius: '15px' }} src={x.img}/>
                                <Box height={'70px'}  mb={1}>
                                    <Typography sx={{ textAlign: 'center', marginTop: '15px', fontSize: '12px' }}>{x.title}</Typography>
                                </Box>
                            </Box>
                        )
                    }
                </Box>
                <Box flexWrap={'wrap'} sx={{ borderRadius: '15px' }} display={{ xs: 'none', md: 'flex' }} justifyContent={'space-around'}>
                    {
                        values.map(x =>
                            <Box onClick={() => openInNewTab(x.link)} sx={{ cursor: 'pointer', '&:hover': { boxShadow: '0px 0px 20px 20px rgba(46,164,57,0.1)' }, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)', width: { md: '360px', lg: '180px' }, padding: '15px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                <img style={{ height: '170px', objectFit: 'cover', borderRadius: '15px' }} src={x.img}/>
                                <Box height={'80px'}>
                                    <Typography sx={{ textAlign: 'center', marginTop: '15px', fontSize: { md: '18px', lg: '12px' } }}>{x.title}</Typography>
                                </Box>
                            </Box>
                        )
                    }
                </Box>
            </Box>
        </Container>
    )
}

export default Partners;