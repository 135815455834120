import {connect} from "react-redux";
import React from 'react';
import Language from "./Language";
import {changeLanguage} from "../../redux/language-reducer";

const LanguageContainer = React.memo((props) => {
    return (
        <Language { ...props } />
    )
})

const mapStateToProps = (state) => {
    return {
        language: state.language.language
    };
}


export default connect(mapStateToProps, { changeLanguage })(LanguageContainer);
