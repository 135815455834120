import {Box, Container, Typography} from "@mui/material";
import {Map, Placemark, TypeSelector, YMaps, ZoomControl} from "@pbe/react-yandex-maps";
import {useEffect, useState} from "react";
import chargeStation from '../../images/chargeStation.png'
import MapIcon from '@mui/icons-material/Map';
import NewspaperIcon from "@mui/icons-material/Newspaper";


const values = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "0447d060-1493-47d9-8c10-014f87cee222",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.08014684770382,
                    43.12850442311076
                ]
            },
            "properties": {
                "name": "Shymbulak #1",
                "address": "ул. Керей, Жанибек хандар 558/1",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "f7c1d125-254d-4e62-a3ac-06ed998936c3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -26.354744978390123,
                    21.03218105218336
                ]
            },
            "properties": {
                "name": "TEST EVS001",
                "address": "",
                "status": 0,
                "online": false,
                "icon": null
            }
        },
        {
            "id": "deb72ad1-959b-410e-9d09-86c021c5b3fa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.92062966817397,
                    43.21378328646243
                ]
            },
            "properties": {
                "name": "Ботанический сад",
                "address": "Аль-Фараби 71А",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "bcdd8e51-0801-4581-bcdf-435ffb482b09",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.95121983632148,
                    43.23282724807905
                ]
            },
            "properties": {
                "name": "Ramstore All in.",
                "address": "пр-т. Назарбаева, 226",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "3c9509fb-0aaa-462f-9055-2bbbb8c7d111",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    78.38535754867466,
                    45.017463107675724
                ]
            },
            "properties": {
                "name": "Станция скорой помощи",
                "address": "проспект Нурсултана Назарбаева 57",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "c628d8a4-7ac0-44fa-ad50-0a60d9c9eb56",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    75.10725192704201,
                    43.402529862352765
                ]
            },
            "properties": {
                "name": "АЗС Royal Petrol #201 (Отар)",
                "address": "",
                "status": 1,
                "online": true,
                "icon": "https://ccms.evs.kz/media/chargepoints/RP_dKam23f.jpg"
            }
        },
        {
            "id": "a9d3a38e-fecc-467d-8d81-0ac45a894bdf",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.85500597039066,
                    43.243688603288
                ]
            },
            "properties": {
                "name": "ЖК Everest #1",
                "address": "",
                "status": 0,
                "online": false,
                "icon": null
            }
        },
        {
            "id": "fb5183c6-fc0b-4c35-b19f-89475d3278d5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.14967553747148,
                    43.228146796489106
                ]
            },
            "properties": {
                "name": "Oi-Qaragai (DC)",
                "address": "Ущелье Ой-Карагай, 225",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "5b04a961-1377-4d43-86ba-46d1662b2768",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.9592444492034,
                    43.24195137205188
                ]
            },
            "properties": {
                "name": "Парковка Кок Тобе",
                "address": "Достык 104Б",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "7e28e5f3-a599-48dd-8782-a5681960eae2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.36378735139633,
                    43.268796128701744
                ]
            },
            "properties": {
                "name": "СОК Ак-Булак #2",
                "address": "ул. Солдатское ущелье, 1а",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "afe5f554-23e4-4bd6-96ad-76b12b1a8aa9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.14945613263747,
                    43.227718803432936
                ]
            },
            "properties": {
                "name": "Oi-Qaragai #3",
                "address": "Ущелье Ой-Карагай, 225",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "473c71c5-c741-48a4-bceb-8393739497ed",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.93263708474298,
                    43.21418728333762
                ]
            },
            "properties": {
                "name": "Sadu, Invictus, Ulys #1",
                "address": "проспект Aль-Фараби 128/7",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "e26b57e8-e327-4bf3-be49-e4a2c9c5d2b9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.96073279878588,
                    43.22615586215158
                ]
            },
            "properties": {
                "name": "Hotel Premier Alatau",
                "address": "проспект Достык 105",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "8e215fff-b252-418f-acae-371c83667bb7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.07790016055175,
                    43.89294437380137
                ]
            },
            "properties": {
                "name": "Комплекс Avrora",
                "address": "ул. Индустриальная, 41/5",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "d5e09c5d-3de2-436d-9b13-2cd4503f2684",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.07999990769302,
                    43.12870484436431
                ]
            },
            "properties": {
                "name": "Shymbulak #4",
                "address": "ул. Керей, Жанибек хандар 558/1",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "ef5f3690-949a-48d9-ba8b-52b95fc7cbb5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.9747434127661,
                    43.23346073507773
                ]
            },
            "properties": {
                "name": "Коктобе",
                "address": "",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "f445ba8d-17f5-4ed9-8484-6024e5e318ab",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.14929922341092,
                    43.22747958786276
                ]
            },
            "properties": {
                "name": "Oi-Qaragai #2",
                "address": "Ущелье Ой-Карагай, 225",
                "status": 0,
                "online": false,
                "icon": null
            }
        },
        {
            "id": "dfaec4bb-81dc-4034-bd4d-95fdecd2c6c8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.14963852285193,
                    43.227870072248436
                ]
            },
            "properties": {
                "name": "Oi-Qaragai #4",
                "address": "Ущелье Ой-Карагай, 225",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "2ba7a235-f07a-4e97-8092-328633b950df",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.84153426788693,
                    43.21296670968609
                ]
            },
            "properties": {
                "name": "ТРЦ спутник #1",
                "address": "",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "5f6eb39d-279b-48c8-bf18-519ffaca44f0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.93911575274923,
                    43.21292183259232
                ]
            },
            "properties": {
                "name": "Esentai city #2",
                "address": "ул. Жамакаева",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "8c4f29a9-60d2-41b7-98a4-c0ff06bcf941",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.84159850345775,
                    43.21295662609566
                ]
            },
            "properties": {
                "name": "ТРЦ спутник #2",
                "address": "",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "73086f92-af06-4d96-92b8-6661f2eb27db",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.96030317872462,
                    43.22771986893203
                ]
            },
            "properties": {
                "name": "АЗС Royal Petrol #10",
                "address": "проспект Достык 99/1",
                "status": 1,
                "online": true,
                "icon": "https://ccms.evs.kz/media/chargepoints/RP_ixYaIVa.jpg"
            }
        },
        {
            "id": "3530505f-6e59-4c25-ab19-1e899f2e5f64",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.94732247148251,
                    43.2723783769291
                ]
            },
            "properties": {
                "name": "ТЦ Мерей",
                "address": "",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "bf32373b-94b7-4704-a36b-55dfa3547968",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    78.31400951433788,
                    43.60191349217591
                ]
            },
            "properties": {
                "name": "Zhibek Zholy Шелек",
                "address": "Улица Абая, 1/1",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "998ed4e7-db00-4c37-8bde-da28efdb4f60",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.03648953557627,
                    43.17137680172455
                ]
            },
            "properties": {
                "name": "Samal Resort & SPA #2",
                "address": "Керей-Жанибек хандар, 548/1",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "4cad32b3-a8f3-4483-b04e-73bf79de609b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.93271755101112,
                    43.214160499801004
                ]
            },
            "properties": {
                "name": "Sadu, Invictus, Ulys #2",
                "address": "проспект Aль-Фараби 128/7",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "76e1f2c4-1bca-4621-b54d-b45c03e08def",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.93903683545169,
                    43.212946174898285
                ]
            },
            "properties": {
                "name": "Esentai city #1",
                "address": "ул. Жамакаева",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "86e8960c-5db8-4a73-ab54-1c1bb21448e2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.14926703690453,
                    43.22724467612366
                ]
            },
            "properties": {
                "name": "Oi-Qaragai #1",
                "address": "Ущелье Ой-Карагай, 225",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "750167ab-6a8d-4dde-bb39-d90afc000d93",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.85513239839159,
                    43.243710441550256
                ]
            },
            "properties": {
                "name": "ЖК Everest #2",
                "address": "",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "be055152-3cbc-4c73-98d0-db4506a77321",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.83585502196061,
                    43.24475921558035
                ]
            },
            "properties": {
                "name": "ТРЦ Аsia Park",
                "address": "проспект Райымбека, 514а",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "eddf136a-644f-4630-8d37-d85ca98c4200",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.92307769181348,
                    43.23781574401872
                ]
            },
            "properties": {
                "name": "Центральный стадион #1",
                "address": "ул. Сатпаева 29/3",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "124fb020-1367-40bc-afb3-53d309b474c3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    80.23807789516896,
                    44.19868856950661
                ]
            },
            "properties": {
                "name": "SEZ Khorgos",
                "address": "пос. Нуркент, СЭЗ «Хоргос Восточные ворота»",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "ac550fd9-4c23-4766-bb52-e2215a2401f7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.08010598968218,
                    43.128563290821525
                ]
            },
            "properties": {
                "name": "Shymbulak #2",
                "address": "ул. Керей, Жанибек хандар 558/1",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "57c2696f-e8fb-4c67-8c60-67d96cfa3a35",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.03541128755121,
                    43.172315751906964
                ]
            },
            "properties": {
                "name": "Samal Resort & SPA #1",
                "address": "Керей-Жанибек хандар, 548/1",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "7efecbda-1ecb-4bbc-ad86-5450c9873b31",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.08005806121193,
                    43.12863685442869
                ]
            },
            "properties": {
                "name": "Shymbulak #3",
                "address": "ул. Керей, Жанибек хандар 558/1",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "df8869ce-1291-40b0-99ac-115c3e416533",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.99940296785765,
                    43.30004789159801
                ]
            },
            "properties": {
                "name": "Рынок Семиречье #1",
                "address": "",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "a3f27cf2-7f50-4d95-b945-2d5ca9a66861",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.00235701035307,
                    43.30050401200214
                ]
            },
            "properties": {
                "name": "Рынок Семиречье #2",
                "address": "",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "af361b2a-353a-4836-bd59-69e456976c65",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.92302136542305,
                    43.237929469426746
                ]
            },
            "properties": {
                "name": "Центральный стадион #2",
                "address": "ул. Сатпаева 29/3",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "4eceae1f-a9d3-4a9b-8575-bbb3686252aa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    71.40922306973964,
                    51.14007482247066
                ]
            },
            "properties": {
                "name": "Ресторан Rubai",
                "address": "просп. Туран 29, Астана",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "c784e06c-b068-42aa-91c8-9de3c6b2fe9f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    77.36357574641961,
                    43.27037380472015
                ]
            },
            "properties": {
                "name": "СОК Ак-Булак #1",
                "address": "ул. Солдатское ущелье, 1а",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "ec233135-77e0-4b8c-8bc4-0be1921c926c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    78.36244760852965,
                    45.010417419028094
                ]
            },
            "properties": {
                "name": "Автосервис \"DRIVE\"",
                "address": "г. Талдыкорган, ул. Желтоксан, 176-А",
                "status": 1,
                "online": true,
                "icon": null
            }
        },
        {
            "id": "e2bd873a-5eae-45df-af08-d7be710cdb35",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    76.90765391664478,
                    43.21297744061989
                ]
            },
            "properties": {
                "name": "Ходжанова #1",
                "address": "Ходжанова 41Б",
                "status": 1,
                "online": true,
                "icon": null
            }
        }
    ]
}

const ChargersMap = ({ chargers, getChargers, languageValues }) => {

    useEffect(() => {
    }, [])

    const [currentCharger, setCurrentCharger] = useState(null)
    const handleChange = (charger) => {
        console.log(charger)
        setCurrentCharger(charger)
    }
    return (
        <Container p={4}>
            <Box mb={2} sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <MapIcon sx={{ width: { xs: '30px', sm: '50px' }, height: { xs: '30px', sm: '50px' } }}/>
                <Typography sx={{ marginLeft: '10px', fontSize: { xs: '24px' } }} variant={'h4'}>{languageValues?.mapHeader}</Typography>
            </Box>
            <Box sx={{ position: 'relative' }}>
                <YMaps>
                    <Map width={'100%'} height={'600px'} defaultState={{center: [43.577217, 76.125259], zoom: 9}}>
                        {values.features.map(x =>
                            <Placemark onMouseEnter={() => handleChange(x)} options={{ iconLayout: 'default#image', iconImageSize: [40, 40], iconImageHref: x.properties.icon != null ? 'https://ccms.evs.kz/media/chargepoints/rp_logo.png' : chargeStation }} geometry={[x.geometry.coordinates[1], x.geometry.coordinates[0]]}/>
                        )}
                        <ZoomControl/>
                        <TypeSelector/>
                    </Map>
                </YMaps>
                {
                    currentCharger &&
                    <Box m={2} p={3} sx={{ background: 'white', borderRadius: '15px', position: 'absolute', top: 0, left: 0 }}>
                        <Typography>Станция <strong>{currentCharger.properties.name}</strong></Typography>
                        {currentCharger.properties.address != "" && <Typography>Адрес {currentCharger.properties.address}</Typography>}
                    </Box>
                }
            </Box>
        </Container>
    )
}

export default ChargersMap;