import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunkMiddleWare from "redux-thunk";
import languageReducer from "./language-reducer";
import newsReducer from "./news-reducer";
import chargersReducer from "./chargers-reducer";

let reducers = combineReducers({
    language: languageReducer,
    news: newsReducer,
    chargers: chargersReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleWare)));

export default store;
