import {connect} from "react-redux";
import React from 'react';
import SendRequestDialog from "./SendRequestDialog";
import {sendRequest} from "../../redux/requests-reducer";

const SendRequestDialogContainer = React.memo((props) => {
    return (
        <SendRequestDialog { ...props } />
    )
})

const mapStateToProps = (state) => {
    return {

    };
}


export default connect(mapStateToProps, { sendRequest })(SendRequestDialogContainer);
