import axios from "axios";
const fileDownload = require('js-file-download')

//const ENV = "DEV";
const ENV = "PROD";

const getUrl = (ENV) => {
    switch (ENV) {
        case "DEV":
            return "https://localhost:7210/"
        case "PROD":
            return "https://yuku.kz/"
    }
}

const BASE_URL = getUrl(ENV);



const instance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true
})



export const newsAPI = {
    getNews() {
        return instance.get(`news`).then(response => response.data);
    },
    getNewsItem(id) {
        return instance.get(`news/getNewsItem?id=${id}`).then(response => response.data);
    }
}


export const chargersAPI = {
    getChargers() {
        return axios.get(`https://ccms.evs.kz/api/geo/chargepoints/`).then(response => response.data);
    }
}

export const requestsAPI = {
    sendRequest(request) {
        return instance.post(`requests`, request).then(response => response.data);
    }
}