import {connect} from "react-redux";
import React from 'react';
import NewsItem from "./NewsItem";
import {getNewsItem} from "../../redux/news-reducer";

const NewsItemContainer = React.memo((props) => {
    return (
        <NewsItem { ...props } />
    )
})

const mapStateToProps = (state) => {
    return {
        newsItem: state.news.newsItem,
        newsItemLoading: state.news.newsItemLoading
    };
}


export default connect(mapStateToProps, { getNewsItem })(NewsItemContainer);
