export const ruValues = {
    homeLink: 'Главная',
    productsLink: 'Наша продукция',
    contactsLink: 'Контакты',
    aboutLink: 'О нас',
    mapLink: 'Карта',
    partnersLink: 'Партнеры',
    welcomeHeader: 'Добро пожаловать на сайт компании EVS!',
    welcomeDescription: 'EV Solutions — компания в сфере развития инфраструктуры для электрического транспорта. Основной деятельностью является развитие зарядных сетей, с целью оказания услуг по зарядке электротранспорта',
    usersLink: 'Пользователям',
    chargeWithUs: 'Заряжайся с нами!',
    downloadOurApp: 'Скачай приложение на свой телефон!',
    chargeWithApp: 'Заряди свой электромобиль через приложение EVS',
    sendRequestButtonTitle: 'Отправить запрос',
    evsStations: 'Станции EVS',
    ourServices: {
        title: 'Наши услуги',
        description: 'Мы предоставляем полный спектр услуг для электромобилей: сеть станций зарядки электромобилей, приложение для удобного контроля и другие сопутствующие сервисы. Наша цель - сделать ваш опыт использования электромобиля максимально удобным и эффективным.',
        network: {
            title: 'Сеть зарядных станций',
            description: 'На данный момент в нашей сети более 40 зарядных станций'
        },
        mobileApp: {
            title: 'Мобильное приложение',
            description: 'Удобное приложение с картой, статистикой и аналитикой'
        },
        supply: {
            title: 'Поставка оборудования',
            description: 'Мы поставляем широкий спектр оборудования для электротранспорта'
        },
        home: {
            title: 'Домашние станции',
            description: 'Устанавливаем домашние станции в жилых комплексах'
        }
    },
    becomePartner: 'Стать партнером',
    newsHeader: 'Новости EVS',
    faqHeader: 'Вопросы и ответы',
    partners: 'Партнеры EVS',
    faq: [
        {
            question: 'Как узнать стоимость зарядки для моего электромобиля?',
            answer: 'Стоимость сессии рассчитывается на основе времени зарядки. Актуальную стоимость минуты зарядки можно уточнить в приложении. Тарифы могут отличаться в зависимости от города, дня недели и спроса.'
        },
        {
            question: 'Как пользоваться приложением EVS?',
            answer: 'Для использования приложения, сначала необходимо скачать его из App Store или Google Play. Затем зарегистрируйтесь в приложении, пополните свой кошелек, используя банковскую карту (VISA/MASTERCARD) или Kaspi, чтобы осуществлять оплату. После успешной регистрации и пополнения кошелька, выберите ближайшую станцию зарядки, подключите электромобиль, и стоимость зарядки будет автоматически вычитаться из вашего кошелька на основе времени, которое ваш электромобиль провел на зарядке.'
        },
        {
            question: 'Можно ли оплатить зарядку через Kaspi без банковской карты?',
            answer: 'Да, приложение поддерживает оплату через Kaspi. Если у вас есть аккаунт в Kaspi, вы можете привязать его к приложению и осуществлять оплату за зарядку напрямую со своего Kaspi-счета без необходимости предоставления данных банковской карты.'
        },
        {
            question: 'Можно ли просматривать историю своих зарядок через приложение?',
            answer: 'Да, приложение предоставляет возможность просматривать историю всех предыдущих зарядок. Вы сможете увидеть даты, время и продолжительность каждой зарядки, а также сумму, которую вы заплатили за каждую из них.'
        },
        {
            question: 'Как мне найти ближайшую доступную станцию зарядки через приложение?',
            answer: 'Приложение имеет функцию поиска ближайших станций зарядки. Она основана на вашем текущем местоположении, которое приложение определит автоматически. Вы сможете увидеть список доступных станций зарядки и выбрать наиболее удобную для вас.'
        },
        {
            question: 'Какая поддержка предоставляется в случае проблем с оплатой или зарядкой?',
            answer: 'Если у вас возникли проблемы с оплатой или зарядкой, вы можете обратиться в службу поддержки приложения. В приложении должны быть указаны контактные данные для связи с поддержкой, например, телефон или адрес электронной почты, где вы сможете сообщить о проблеме и получить помощь.'
        },
        {
            question: 'Как работает ценообразование?',
            answer: 'Наши станции зарядки электромобилей предлагают разнообразное ценообразование, учитывая различные факторы. Для зарядных единиц (Е) на станциях быстрого заряда цена колеблется от 80 до 100 тенге в зависимости от расположения станции. Услуга заряда на станциях быстрого заряда стоит 100 тенге в первый час и 200 тенге за каждый последующий час. На станциях медленного заряда зарядная единица стоит 60 тенге, а услуга заряда стоит 10 тенге в час.\n' +
                '\n' +
                'Общая стоимость зарядки вашего электромобиля будет зависеть от времени, проведенного на станции, количества зарядных единиц, а также выбранного типа станции. Пожалуйста, ознакомьтесь с актуальными тарифами в нашем мобильном приложении, чтобы точно рассчитать общие затраты для вашей зарядки.'
        }
    ],
    whyChooseUs: {
        title: 'Почему стоит выбрать именно нас?',
        tips: [
            'Наш главный приоритет – значительное упрощение эксплуатации электрокаров',
            'Мы предоставляем качественное и проверенное оборудование',
            'Мы всегда готовы рассмотреть предложения клиентов и реализовать новый функционал',
            'Мы идем в ногу со временем, и боремся за экологию нашей страны'
        ]
    },
    mapHeader: 'Карта станций EVS'
}