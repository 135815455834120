import {Box, Button, Container, Divider, IconButton, Menu, MenuItem, Tab, Tabs, Typography} from "@mui/material";
import logo from "../../images/evs-logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LanguageContainer from "../Language/LanguageContainer";
import {links} from "../../common/links";
import {useLocation, useNavigate} from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";

const Footer = ({ languageValues, handleOpenSendRequest }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };
    const openLink = (url) => {
        window.open(url);
    };
    return (
    <Container>
        <Box mt={4} mb={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'flex' }} flexDirection={'column'} justifyContent={{ xs: 'center', sm: 'center', md: 'space-between' }}  sx={{ borderRadius: '15px', background: 'linear-gradient(135deg, rgba(0, 0, 0, 0.04), #EFF2ED)' }} alignItems={'center'}>
            <Box width={'100%'} pt={4} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box pl={4} flexGrow={1} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <img src={logo} width={160}/>
                </Box>
                <Box pr={4} flexGrow={6} display={'flex'} justifyContent={'flex-end'}>
                    <Box ml={2}>
                        <Button onClick={() => openInNewTab('https://www.instagram.com/evs.kz/')}>
                            <InstagramIcon sx={{ marginRight: '5px', color: '#70A366' }}/>
                            <Typography sx={{ color: 'black', textTransform: 'lowerCase' }}>evs.kz</Typography>
                        </Button>
                    </Box>
                    <Box ml={2}>
                        <a href="tel:+77714277000">
                            <Button>
                                <PhoneIphoneIcon sx={{ marginRight: '5px', color: '#70A366' }}/>
                                <Typography sx={{ color: 'black', textTransform: 'capitalize' }}>+77714277000</Typography>
                            </Button>
                        </a>
                    </Box>
                    <Box ml={2}>
                        <a href="tel:+77273783293">
                            <Button>
                                <LocalPhoneIcon sx={{ marginRight: '5px', color: '#70A366' }}/>
                                <Typography sx={{ color: 'black', textTransform: 'capitalize' }}>+77273783293</Typography>
                            </Button>
                        </a>
                    </Box>
                    <Box ml={2}>
                        <a href="mailto:info@evs.kz">
                            <Button>
                                <EmailIcon sx={{ marginRight: '5px', color: '#70A366' }}/>
                                <Typography sx={{ color: 'black', textTransform: 'lowerCase' }}>info@evs.kz</Typography>
                            </Button>
                        </a>
                    </Box>
                    <Box ml={2}>
                        <Button onClick={() => openInNewTab('https://wa.me/+77714277000?text=Добрый день! Нашел вас на evs.kz')}>
                            <WhatsAppIcon sx={{ marginRight: '5px', color: '#70A366' }}/>
                            <Typography sx={{ color: 'black', textTransform: 'capitalize' }}>WhatsApp</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box mt={2} sx={{ width: '90%', padding: '15px' }}>
                <Divider sx={{ padding: '0 10px' }}/>
            </Box>
            <Box display={'flex'} mb={2} width={'100%'} justifyContent={'space-between'}>
                <Box pl={4}>
                    <Typography sx={{ fontSize: '36px', fontWeight: '400' }}>EV Solutions</Typography>
                    <Typography>г. Алматы, Улица Ураза Исаева 15</Typography>
                    <Box mt={4}>
                        <Button onClick={() => handleOpenSendRequest()} sx={{ backgroundColor: 'black', color: 'white', padding: '10px 15px', '&:hover': { backgroundColor: 'black' } }}><strong>{languageValues?.sendRequestButtonTitle}</strong></Button>
                    </Box>
                </Box>
                <Box width={'300px'} mr={3}>
                    <Box>
                        {
                            links.map(
                                (x, index) => <MenuItem sx={{ justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start' }} onClick={() => navigate(x.link)}>
                                    <Typography color={location.pathname === x.link ? 'primary' : 'inherit'} textAlign={'left'} sx={{ fontWeight: location.pathname === x.link ? 'bold' : 'regular' }}>{languageValues[x.title]}</Typography>
                                    { location.pathname === x.link && <Box sx={{ width: '100%', borderBottom: '1.5px solid green' }}></Box> }
                                </MenuItem>
                            )
                        }
                    </Box>
                    <Box pl={1} display={'flex'} justifyContent={'flex-start'}>
                        <LanguageContainer/>
                    </Box>
                </Box>
            </Box>
        </Box>
        <Box mb={2} mt={4} display={{ xs: 'block', sm: 'block', md: 'block', lg: 'none' }} sx={{ borderRadius: '15px', background: 'linear-gradient(135deg, rgba(0, 0, 0, 0.04), #EFF2ED)' }}>
            <Box pt={4} display={'flex'} alignItems={'center'} flexDirection={'column'}>
                <img src={logo} width={100}/>
                <Box mt={4} display={'flex'} alignItems={'center'}>
                    <IconButton onClick={() => openInNewTab('https://www.instagram.com/evs.kz/')} sx={{ color: 'black', marginLeft: '8px' }}>
                        <InstagramIcon/>
                    </IconButton>
                    <a href="tel:+77714277000">
                        <IconButton  sx={{ color: 'black', marginLeft: '8px' }}>
                            <PhoneIphoneIcon/>
                        </IconButton>
                    </a>
                    <a href="tel:+77273783293">
                        <IconButton  sx={{ color: 'black', marginLeft: '8px' }}>
                            <LocalPhoneIcon/>
                        </IconButton>
                    </a>
                    <a href={'mailto:info@evs.kz'}>
                        <IconButton  sx={{ color: 'black', marginLeft: '8px' }}>
                            <AlternateEmailIcon/>
                        </IconButton>
                    </a>
                    <IconButton onClick={() => openInNewTab('https://wa.me/+77714277000?text=Добрый день! Нашел вас на evs.kz')} sx={{ color: 'black', marginLeft: '8px' }}>
                        <WhatsAppIcon/>
                    </IconButton>
                </Box>
            </Box>
            <Box p={2}>
                <Box>
                    {
                        links.map(
                            (x, index) => <MenuItem sx={{ justifyContent: 'center' }} onClick={() => navigate(x.link)}>
                                <Typography color={location.pathname === x.link ? 'primary' : 'inherit'} textAlign={'center'} sx={{ fontWeight: location.pathname === x.link ? 'bold' : 'regular' }}>{languageValues[x.title]}</Typography>
                            </MenuItem>
                        )
                    }
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <LanguageContainer/>
                </Box>
            </Box>
        </Box>
    </Container>
    )
}

export default Footer;