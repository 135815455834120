import {Box, Button, Container, IconButton, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CheckCircle, PictureAsPdf} from "@mui/icons-material";
import {useState} from "react";
import SendRequestDialogContainer from "../SendRequestDialog/SendRequestDialogContainer";
import priceList from '../../files/EVS-price-list-22-04.pdf'
import partnership from '../../files/partnership.pdf'

const PartnershipOption = ({ title, description, partnerShare, evsShare, sendRequest }) => {

    const [sendRequestOpened, setSendRequestOpened] = useState(false)

    const handleOpen = () => {
        setSendRequestOpened(true)
    }

    const handleClose = () => {
        setSendRequestOpened(false)
    }

    return (
        <Card sx={{
            maxWidth: 345,
            margin: 2,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 2,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        }}>
            <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                height: '100%',
                padding: 2,
                borderRadius: 2,
            }}>
                <div>
                    <Box sx={{
                        color: 'white',
                        padding: '5px 15px',
                        borderRadius: '10px',
                        background: 'linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(10, 157, 121, 1) 49%, rgba(10, 157, 72, 1) 100%)'
                    }}>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 1, lineHeight: 1.1, height: '120px' }}>
                            <CheckCircle sx={{ marginRight: 1, paddingTop: '5px' }} />
                            {title}
                        </Typography>
                    </Box>
                    <Typography mt={2} variant={'body2'} align={'justify'} sx={{ height: '180px', marginBottom: 1, lineHeight: 1.5 }}>{description}</Typography>
                </div>
                <Box mt={2} >
                    <Typography textAlign={'center'}><strong>Распределение дохода:</strong></Typography>
                    <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '36px' }}>{partnerShare}%</Typography>
                            <Typography paragraph sx={{ lineHeight: 1.5 }}>Партнер</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '36px' }}>{evsShare}%</Typography>
                            <Typography paragraph sx={{ lineHeight: 1.5 }}>EVS</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ margin: '0 auto' }}>
                    <Button onClick={handleOpen} variant={'contained'} size={'large'} sx={{ color: 'white' }}>Отправить заявку</Button>
                </Box>
            </CardContent>
            <SendRequestDialogContainer partnership={true} sendRequest={sendRequest} handleClose={handleClose} open={sendRequestOpened}/>
        </Card>
    );
};

const BecomePartner = ({ sendRequest }) => {



    return (
        <Container sx={{
        }}>
            <Box pt={3} pl={3} pr={3}>
                <Typography variant={'h4'}>
                    Станьте нашим партнером
                </Typography>
                <Typography variant={'body1'} mt={2}>
                    Ознакомьтесь с различными вариантами сотрудничества для установки электрозарядных станций для электромобилей. Мы предлагаем гибкие условия сотрудничества и современные технологии зарядки, которые помогут вам в развитии вашего бизнеса в сфере зеленой энергетики
                </Typography>
                <Box mt={3} display={'flex'} flexDirection={ 'column' }>
                    <a href={priceList} target="_blank"
                       rel="noreferrer">
                        <Button fullWidth variant={'contained'} sx={{ width: { xs: '100%', md: '50%' }, marginTop: '10px', color: 'white' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>Актуальный прайс-лист</Typography>
                            <IconButton sx={{ marginLeft: '7px', color: 'white' }}>
                                <PictureAsPdf/>
                            </IconButton>
                        </Button>
                    </a>
                    <a href={partnership} target="_blank"
                       rel="noreferrer">
                        <Button fullWidth variant={'contained'} sx={{ width: { xs: '100%', md: '50%' }, marginTop: '10px', color: 'white' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>Актуальные варианты сотрудничества</Typography>
                            <IconButton sx={{ marginLeft: '7px', color: 'white' }}>
                                <PictureAsPdf/>
                            </IconButton>
                        </Button>
                    </a>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between' }}>
                <PartnershipOption sendRequest={sendRequest}
                    title="Установка электрозарядной станции для электрокаров (ЭЗС)"
                    description="Установка ЭЗС принадлежащей EVS на территории партнера, за счет средств EVS. Технические условия партнера. EVS ежемесячно возмещает партнеру стоимость использованной электроэнергии согласно показаниям прибора учета. EVS обеспечивает подключение к мобильному приложению EVS.kz и предоставляет доступ к личному кабинету партнера."
                    partnerShare={10}
                    evsShare={90}
                />
                <PartnershipOption sendRequest={sendRequest}
                    title="Установка ЭЗС принадлежащей EVS на территории партнера, за счет средств партнера"
                    description="Технические условия партнера. Партнер самостоятельно оплачивает стоимость использованной электроэнергии согласно показаниям прибора учета. EVS обеспечивает подключение к мобильному приложению EVS.kz и предоставляет доступ к личному кабинету партнера."
                    partnerShare={60}
                    evsShare={40}
                />
                <PartnershipOption sendRequest={sendRequest}
                    title="Установка ЭЗС партнера на территории партнера, за счет средств партнера"
                    description="Технические условия партнера. Партнер самостоятельно оплачивает стоимость использованной электроэнергии согласно показаниям прибора учета. EVS обеспечивает подключение к мобильному приложению EVS.kz и предоставляет доступ к личному кабинету партнера."
                    partnerShare={90}
                    evsShare={10}
                />
            </Box>
        </Container>
    )
}

export default BecomePartner;