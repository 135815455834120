import {Box, Button, Container, Typography} from "@mui/material";
import lk from "../../images/lk.jpg";
import chooseus from "../../images/chooseus.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const WhyChooseUs = ({ languageValues, handleOpenSendRequest }) => {
    return (
        <Container>
            <Box mt={4} sx={{ }} display={'flex'} flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}>
                <Box boxSizing={'border-box'}  width={'100%'} display={'flex'} flexDirection={'column'} p={{ xs: 4, sm: 6 }} sx={{ marginBottom: { xs: '16px', sm: '16px', md: '10px' } ,  borderRadius: { xs: '15px', md: '15px 0 0 15px' }, background: 'linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(10, 157, 121, 1) 49%, rgba(10, 157, 72, 1) 100%)' }} flexGrow={1}>
                    <Typography variant={'h4'} sx={{ color: 'white', fontWeight: 'bold' }}>{languageValues?.whyChooseUs.title}</Typography>
                    <img src={chooseus} width={'100%'} style={{ margin: '15px 0', borderRadius: '15px' }}/>
                </Box>
                <Box boxSizing={'border-box'} width={'100%'} p={{ xs: 4, sm: 6 }} sx={{ marginBottom: '10px', backgroundColor: 'black', borderRadius: { xs: '15px', md: '0 15px 15px 0' } }} flexGrow={1}>
                    {
                        languageValues?.whyChooseUs?.tips.map(x =>
                            <Box display={'flex'} mb={2}>
                                <CheckCircleIcon sx={{ color: '#70A366' }}/>
                                <Typography sx={{ marginLeft: '10px', color: 'white', fontSize: { xs: '14px', md: '18px' } }}>
                                    {x}
                                </Typography>
                            </Box>
                        )
                    }
                </Box>
            </Box>
        </Container>
    )
}

export default WhyChooseUs;