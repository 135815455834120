import {requestsAPI} from "../api/api";

let initialState = {
}

const requestsReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}



export const sendRequest = (request) => async (dispatch) => {
    await requestsAPI.sendRequest(request);
}



export default requestsReducer;