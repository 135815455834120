import {newsAPI} from "../api/api";

const SET_NEWS = 'SET_NEWS'
const SET_NEWS_ITEM = 'SET_NEWS_ITEM'
const SET_NEWS_ITEM_LOADING = 'SET_NEWS_ITEM_LOADING'

let initialState = {
    news: [],
    newsItem: null,
    newsItemLoading: false
}

const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEWS:
            return { ...state, news: action.news }
        case SET_NEWS_ITEM:
            return { ...state, newsItem: action.newsItem }
        case SET_NEWS_ITEM_LOADING:
            return { ...state, newsItemLoading: action.loading }
        default:
            return state;
    }
}

const setNews = (news) => ({ type: SET_NEWS, news })
const setNewsItem = (newsItem) => ({ type: SET_NEWS_ITEM, newsItem})
const setNewsItemLoading = (loading) => ({ type: SET_NEWS_ITEM_LOADING, loading })


export const getNews = () => async (dispatch) => {
    let response = await newsAPI.getNews();
    dispatch(setNews(response))
}

export const getNewsItem = (id) => async (dispatch) => {
    dispatch(setNewsItemLoading(true))
    let response = await newsAPI.getNewsItem(id);
    dispatch(setNewsItem(response))
    setTimeout(() => {
        dispatch(setNewsItemLoading(false))
    }, 400)
}



export default newsReducer;