export const enValues = {
    homeLink: 'Home',
    contactsLink: 'Contacts',
    productsLink: 'Our products',
    aboutLink: 'About us',
    usersLink: 'Terms',
    mapLink: 'Map',
    welcomeHeader: 'Welcome to the EVS website!',
    welcomeDescription: 'EV Solutions is an infrastructure development company for electric vehicles. The main activity is the development of charging networks, in order to provide services for charging electric vehicles.',
    chargeWithUs: 'Charge with us!',
    downloadOurApp: 'Download the app on your phone!',
    chargeWithApp: 'Charge your electric car with the EVS app',
    sendRequestButtonTitle: 'Send request',
    becomePartner: 'Become a partner',
    evsStations: 'EVS stations',
    ourServices: {
        title: 'Our services',
        description: 'We provide a full range of services for electric vehicles: a network of electric vehicle charging stations, an application for convenient control and other related services. Our goal is to make your electric vehicle experience as convenient and efficient as possible.',
        network: {
            title: 'EV Charging Stations',
            description: 'There are currently more than 40 charging stations in the network'
        },
        mobileApp: {
            title: 'Mobile application',
            description: 'Application with a map, statistics and analytics'
        },
        supply: {
            title: 'Supply of equipment',
            description: 'We supply a wide range of equipment for electric transport'
        },
        home: {
            title: 'Home charging stations',
            description: 'We install home stations in residential complexes'
        }
    },
    faqHeader: 'FAQ',
    partners: 'Our partners',
    newsHeader: 'EVS news',
    faq: [
        {
            question: 'How do I find out the charging cost for my electric vehicle?',
            answer: 'The cost of the session is calculated based on the charging time. The current cost of a minute of charging can be clarified in the application. Rates may vary by city, day of the week and demand.'
        },
        {
            question: 'How to use the EVS application?',
            answer: 'To use the app, you first need to download it from the App Store or Google Play. Then register in the application, replenish your wallet using a bank card (VISA / MASTERCARD) or Kaspi to pay. After successfully registering and topping up your wallet, select the nearest charging station, plug in your electric car, and the charging cost will be automatically deducted from your wallet based on the time your electric car spent charging.'
        },
        {
            question: 'Is it possible to pay for charging through Kaspi without a bank card?',
            answer: 'Yes, the application supports payment via Kaspi. If you have an account in Kaspi, you can link it to the application and pay for charging directly from your Kaspi account without having to provide bank card details.'
        },
        {
            question: 'Can I check my charge history through the app?',
            answer: 'Yes, the application provides the ability to view the history of all previous charges. You will be able to see the dates, times and duration of each charge, as well as the amount you paid for each one.'
        },
        {
            question: 'How can I find the nearest available charging station through the app?',
            answer: 'The application has a function to search for nearby charging stations. It is based on your current location, which the app will determine automatically. You will be able to see a list of available charging stations and choose the most convenient one for you.'
        },
        {
            question: 'What kind of support is provided in case of problems with payment or charging?',
            answer: 'If you have problems with payment or charging, you can contact the application support. The application should include contact details for contacting support, such as a phone number or email address, where you can report a problem and get help.'
        },
        {
            question: 'How does pricing work?',
            answer: 'Our EV charging stations offer a variety of pricing options based on a variety of factors. For charging units (E) at fast charging stations, the price ranges from 80 to 100 tenge, depending on the location of the station. The charging service at fast charging stations costs 100 tenge for the first hour and 200 tenge for each subsequent hour. At slow charging stations, a charging unit costs 60 tenge, and the charging service costs 10 tenge per hour.\n' +
                '\n' +
                'The total cost of charging your electric vehicle will depend on the time spent at the station, the number of charging units, and the type of station chosen. Please check our mobile app for current rates to accurately calculate the total costs for your charging.'
        }
    ],
    whyChooseUs: {
        title: 'Why choose us?',
        tips: [
            'Our main priority is to significantly simplify the operation of electric cars',
            'We provide high quality and proven equipment',
            'We are always ready to consider customer suggestions and implement new functionality',
            'We keep up with the times and improve the ecology of our country'
        ]
    },
    mapHeader: 'EVS stations map'
}