import {
    Box,
    Container,
    Dialog, DialogContent, DialogTitle,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Typography
} from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import InfoIcon from "@mui/icons-material/Info";
import lk from "../../images/lk.jpg";
import ramstore from "../../images/ramstore.jpg";
import rp10 from "../../images/rp10.JPG";
import sadu from "../../images/sadu.jpg";
import shelek from "../../images/shelek.JPG";
import otar from "../../images/otar.JPG";
import samal from "../../images/samal.jpg";
import stad from "../../images/stad.jpg";
import hotelalatau from "../../images/hotel-alatau.jpg";
import botsad from "../../images/botsad.jpg";
import rubai from "../../images/rubai.jpg";
import shymb from "../../images/shymb.jpg";
import sezkhorgos from "../../images/sezkhorgos.jpg";
import akbulak from "../../images/akbulak.jpg";
import tdk from "../../images/tdk.jpg";
import {useState} from "react";
import {ArrowBackIosNew, BrowseGalleryOutlined, Close, Info, PhotoAlbum} from "@mui/icons-material";
import ChargersMap from "../ChargersMap/ChargersMap";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import Carousel from "nuka-carousel";

const Gallery = ({ languageValues }) => {

    const itemData = [
        {
            img: shymb,
            title: 'Shymbulak'
        },
        {
            img: sadu,
            title: 'Invictus Sadu'
        },
        {
            img: ramstore,
            title: 'Ramstore All-in'
        },
        {
            img: samal,
            title: 'Samal Resort'
        },
        {
            img: stad,
            title: 'Центральный стадион'
        },
        {
            img: lk,
            title: 'Oi-Qaragai'
        },
        {
            img: otar,
            title: 'Royal Petrol Otar'
        },
        {
            img: rp10,
            title: 'Royal Petrol 10'
        },
        {
            img: shelek,
            title: 'Шелек, комплекс "Жибек-Жолы"'
        },
        {
            img: hotelalatau,
            title: 'Гостиница Alatau'
        },
        {
            img: sezkhorgos,
            title: 'СЭЗ "Хоргос - Восточные Ворота"'
        },
        {
            img: akbulak,
            title: 'СОК Ак-Булак'
        },
        {
            img: botsad,
            title: 'Ботанический сад'
        },
        {
            img: tdk,
            title: 'пр. Назарбаева 57, г. Талдыкорган'
        },
        {
            img: rubai,
            title: 'Ресторан Rubai, г. Астана'
        },
    ];

    const [chosenItem, setChosenItem] = useState(null)
    const [chosenItemDialog, setChosenItemDialog] = useState(false)

    const handleOpenItem = (item) => {
        setChosenItem(item)
        setChosenItemDialog(true)
    }

    const handleCloseItem = () => {
        setChosenItemDialog(false)
        setChosenItem(null)
    }

    return (
        <Container>
            <Box sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <CollectionsIcon sx={{ width: { xs: '30px', sm: '50px' }, height: { xs: '30px', sm: '50px' } }}/>
                <Typography sx={{ marginLeft: '10px', fontSize: { xs: '24px' } }} variant={'h4'}>
                    {chosenItem ? `Станция ${chosenItem.title}` : languageValues?.evsStations}
                </Typography>
            </Box>
            <Box mt={2} display={{ xs: 'none', sm: 'none', md: 'block' }}>
                <ImageList
                    gap={14}
                    cols={3}
                >
                    {itemData.map((item, index) => (
                        <ImageListItem  sx={{ margin: 0 }} key={item.img}>
                            <img
                                style={{ height: '300px', borderRadius: '15px' }}
                                src={item.img}
                                alt={item.title}
                                loading="lazy"
                            />
                            <ImageListItemBar sx={{ borderRadius: '15px' }} title={item.title} subtitle={'Сатпаева 29/3'} actionIcon={
                                <></>
                            }>
                            </ImageListItemBar>
                        </ImageListItem>
                    ))}

                </ImageList>
            </Box>
            <Box display={{ sm: 'block', xs: 'none', md: 'none' }}>
                <ImageList
                    cols={2}
                >
                    {itemData.map((item) => (
                        <ImageListItem key={item.img}>
                            <img
                                style={{ height: '300px', borderRadius: '15px' }}
                                src={item.img}
                                alt={item.title}
                                loading="lazy"
                            />
                            <ImageListItemBar sx={{ borderRadius: '15px' }} title={item.title} subtitle={'Сатпаева 29/3'} actionIcon={
                                <></>
                            }>
                            </ImageListItemBar>
                        </ImageListItem>
                    ))}
                    }
                </ImageList>
            </Box>
            <Box display={{ xs: 'block', sm: 'none' }}>
                <ImageList
                    cols={1}
                >
                    {itemData.map((item) => (
                        <ImageListItem key={item.img}>
                            <img
                                style={{ height: '300px', borderRadius: '15px' }}
                                src={item.img}
                                alt={item.title}
                                loading="lazy"
                            />
                            <ImageListItemBar sx={{ borderRadius: '15px' }} title={item.title} subtitle={'Сатпаева 29/3'} actionIcon={
                                <></>
                            }>
                            </ImageListItemBar>
                        </ImageListItem>
                    ))}

                </ImageList>
            </Box>
            <Dialog fullWidth maxWidth={'md'} open={chosenItemDialog} onClose={handleCloseItem}>
                {
                    chosenItem &&
                    <>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <DialogTitle sx={{ fontWeight: 'bold', fontSize: '24px' }}>
                                Станция {chosenItem.title}
                            </DialogTitle>
                            <IconButton onClick={handleCloseItem} sx={{ marginRight: '15px' }}>
                                <Close/>
                            </IconButton>
                        </Box>
                        <DialogContent sx={{ paddingTop: 0 }}>
                            <Box display={'flex'} flexDirection={'column'}>
                                <Box mb={2} display={'flex'} alignItems={'center'}>
                                    <CollectionsIcon sx={{ width: { xs: '30px', sm: '50px' }, height: { xs: '30px', sm: '50px' } }}/>
                                    <Typography sx={{ marginLeft: '10px', fontSize: '18px'  }} variant={'h4'}>
                                        Фотографии
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box>
                                        <Carousel defaultControlsConfig={{

                                        }} cellAlign={'center'}>
                                            <img style={{ margin: '0 auto', height: '400px', objectFit: 'cover', display: 'block' }} src={chosenItem.img}/>
                                        </Carousel>

                                    </Box>
                                </Box>
                                <Box display={'flex'} alignItems={'center'} mt={2}>
                                    <Info sx={{ width: { xs: '30px', sm: '50px' }, height: { xs: '30px', sm: '50px' } }}/>
                                    <Typography sx={{ marginLeft: '10px', fontSize: '18px' }} variant={'h4'}>
                                        Информация
                                    </Typography>
                                </Box>
                                <Box mt={3} display={'flex'}>
                                    <YMaps>
                                        <Map height={400} width={'50%'} defaultState={{ center: [chosenItem.latitude, chosenItem.longitude], zoom: 17 }}>
                                            <Placemark geometry={[chosenItem.latitude, chosenItem.longitude]}/>
                                        </Map>
                                    </YMaps>
                                    <Box>
                                        <Typography>
                                            {chosenItem.address}
                                        </Typography>
                                        <Typography>
                                            {chosenItem.description}
                                        </Typography>
                                    </Box>
                                </Box>

                            </Box>
                        </DialogContent>
                    </>
                }
            </Dialog>
        </Container>
    )
}

export default Gallery;