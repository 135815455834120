export const kzValues = {
    homeLink: 'Басты бет',
    contactsLink: 'Байланыстар',
    aboutLink: 'Біздің қызметіміз',
    productsLink: 'Біздің өндіріс',
    partnersLink: 'Партнеры',
    usersLink: 'Ақпарат',
    mapLink: 'Карта',
    welcomeHeader: 'EVS веб-сайтына қош келдіңіз!',
    welcomeDescription: 'EV Solutions – электрлі көліктерге арналған инфрақұрылымды дамытушы компания. Негізгі қызмет түрі – электрлі көліктерді зарядтау бойынша қызмет көрсету мақсатында зарядтау желілерін дамыту.',
    chargeWithUs: 'Бізбен зарядтаңыз!',
    downloadOurApp: 'Қолданбаны телефоныңызға жүктеп алыңыз!',
    chargeWithApp: 'EVS қолданбасымен электр көлігіңізді зарядтаңыз',
    sendRequestButtonTitle: 'Сұрау жіберіңіз',
    evsStations: 'EVS станциялары',
    becomePartner: 'Серіктес болу',
    ourServices: {
        title: 'Біздің қызметтеріміз',
        description: 'Біз электромобильдерге арналған қызметтердің толық спектрін ұсынамыз: электр көліктерін зарядтау станцияларының желісі, ыңғайлы басқаруға арналған қосымша және басқа да тиісті қызметтер. Біздің мақсатымыз - сіздің электрлік көлік тәжірибесін мүмкіндігінше ыңғайлы және тиімді ету.',
        network: {
            title: 'Зарядтау желісі',
            description: 'Қазіргі уақытта біздің желіде 40-тан астам зарядтау станциялары бар'
        },
        mobileApp: {
            title: 'Мобильді қолданба',
            description: 'Ыңғайлы қолданба карта, статистика және аналитика бар'
        },
        supply: {
            title: 'Жабдықпен қамтамасыз ету',
            description: 'Біз электр көлігіне арналған жабдықтардың кең спектрін жеткіземіз'
        },
        home: {
            title: 'Үй станциялары',
            description: 'Тұрғын үй кешендеріне станцияларын орнатамыз'
        }
    },
    faqHeader: 'Сұрақтар мен жауаптар',
    partners: 'Біздің серіктестер',
    faq: [
        {
            question: 'Электр көлігімнің зарядтау құнын қалай білуге болады?',
            answer: 'Сеанс құны зарядтау уақытына қарай есептеледі. Зарядтау минутының ағымдағы құнын өтінімде нақтылауға болады. Тарифтер қалаға, апта күніне және сұранысқа байланысты өзгеруі мүмкін'
        },
        {
            question: 'EVS қолданбасын қалай пайдалануға болады?',
            answer: 'Қолданбаны пайдалану үшін алдымен оны App Store немесе Google Play дүкенінен жүктеп алу керек. Содан кейін қосымшаға тіркеліңіз, төлеу үшін банк картасын (VISA / MASTERCARD) немесе Kaspi арқылы әмияныңызды толтырыңыз. Әмиянды сәтті тіркегеннен және толтырғаннан кейін, ең жақын зарядтау станциясын таңдап, электр көлігіңізді розеткаға қосыңыз және зарядтау құны электр көлігіңіз зарядтауға жұмсаған уақыт негізінде әмияныңыздан автоматты түрде шегеріледі.'
        },
        {
            question: 'Kaspi арқылы төлемді банк картасыз төлеуге болады ма?',
            answer: 'Иә, қолданба барлық алдыңғы зарядтау тарихын қарау мүмкіндігін береді. Сіз әрбір зарядтау күнін, уақытын және ұзақтығын, сондай-ақ әрқайсысы үшін төлеген соманы көре аласыз.'
        },
        {
            question: 'Қолданба арқылы зарядтау тарихын көре аламын ба?',
            answer: 'Иә, қолданба барлық алдыңғы зарядтау тарихын қарау мүмкіндігін береді. Сіз әрбір зарядтау күнін, уақытын және ұзақтығын, сондай-ақ әрқайсысы үшін төлеген соманы көре аласыз.'
        },
        {
            question: 'Қолданба арқылы ең жақын қол жетімді зарядтау станциясын қалай табуға болады?',
            answer: 'Қолданбада жақын маңдағы зарядтау станцияларын іздеу функциясы бар. Ол қолданба автоматты түрде анықтайтын ағымдағы орныңызға негізделген. Сіз қол жетімді зарядтау станцияларының тізімін көре аласыз және өзіңізге ыңғайлысын таңдай аласыз.'
        },
        {
            question: 'Зарядтау немесе төлем кезінде қиындықтар туындаған жағдайда қандай қолдау көрсетіледі?',
            answer: 'Зарядтау немесе төлем жасау кезінде қиындықтар туындаса, қолданбаны қолдау қызметіне хабарласуға болады. Қолданба телефон нөмірі немесе электрондық пошта мекенжайы сияқты қолдау қызметіне хабарласуға арналған байланыс мәліметтерін қамтуы керек, онда мәселе туралы хабарлауға және көмек алуға болады.'
        },
        {
            question: 'Баға қалай жұмыс істейді?',
            answer: 'Біздің электромобильдерді зарядтау станцияларымыз көпшілікті баға беру үшін жеткізілген факторларды қосуды ұсынады. Тез зарядтау бірліктері 80-ден 100 теңгеге дейін болуы мүмкін, станцияның орналасуына байланысты. Тез шарлау станцияларында бағасы бірінші сағатта 100 теңге, әр келесі сағат үшін 200 теңге. Баяу зарядтау станцияларында зарядтау қондырғысы 60 теңге, ал зарядтау қызметі сағатына 10 теңге тұрады.\n' +
                '\n' +
                'Сіздің электромобильді зарядтау жалпы құны станцияда өткен уақыт, зарядтау бірліктерінің саны және түрі бойынша болады. Жалпы құнын толық есептемеу үшін біздің мобильді қолданбада жаңа ағымдағы тарифтерді тексеріңіз.'
        }
    ],
    newsHeader: 'EVS жаңалықтар',
    whyChooseUs: {
        title: 'Неліктен бізді таңдайсыз?',
        tips: [
            'Біздің басты басымдығымыз – электромобильдердің жұмысын айтарлықтай жеңілдету',
            'Біз жоғары сапалы және дәлелденген жабдықты ұсынамыз',
            'Біз әрқашан тұтынушылардың ұсыныстарын қарастыруға және жаңа функцияларды енгізуге дайынбыз',
            'Заман көшіне ілесіп, еліміздің экологиясы үшін күресеміз'
        ]
    },
    mapHeader: 'EVS картасы'
}