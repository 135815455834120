import {Box, Button, Container, Dialog, Typography} from "@mui/material";
import iphone from "../../images/iphone-evs.png";
import appstore from "../../images/appstorelast.png";
import google from "../../images/googlelast.png";
import CardMedia from "@mui/material/CardMedia";
import instruction from '../../videos/evsapp.mp4'
import {useState} from "react";
import {Close} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const Download = ({ languageValues }) => {

    const navigate = useNavigate();

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };
    const openLink = (url) => {
        window.open(url);
    };

    const [instruction, setInstruction] = useState(false)

    const handleOpenInstruction = () => {
        // setInstruction(true)
        openInNewTab('https://www.instagram.com/reel/Cy5UU5TiMVC/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==')
    }

    const handleCloseInstruction = () => {
        setInstruction(false)
    }


    return (
        <Container>
            <InstructionDialog open={instruction} handleClose={handleCloseInstruction}/>
            <Box display={'flex'} flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}>
                <Box pt={{ sm: 3, md: 0, xs: 3 }} mb={{ sm: 3, md: 0, xs: 3 }} pr={2} pl={2} pb={ { xs: 3, sm: 3 } } mr={{ md: 2, sm: 0 }} display={'flex'} boxSizing={'border-box'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} width={{ sm: '100%', md: '50%' }} sx={{ borderRadius: '15px', background: 'linear-gradient(135deg, rgba(0, 0, 0, 0.04), #EFF2ED)' }}>
                    <Typography textAlign={'center'} variant={'h4'} sx={{ fontSize: { md: '20px', lg: '24px', sm: '18px', xs: '20px' }, fontWeight: 'bold', color: 'black' }}>{languageValues.welcomeHeader}</Typography>
                    <Typography textAlign={'center'} sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '15px', padding: { xs: '10px', sm: '10px' } }}>
                        {languageValues.welcomeDescription}
                    </Typography>
                </Box>
                <Box flexGrow={2} p={{ lg: 2, md: 2 }} pb={{ md: 3, sm: 4, xs: 4 }} width={'100%'}  display={'flex'} flexDirection={{ xs: 'column', md: 'row' }}  sx={{ background: 'linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(10, 157, 121, 1) 49%, rgba(10, 157, 72, 1) 100%)', borderRadius: '15px' }} justifyContent={'center'}>
                    <Box mt={{ md: 3 }} display={'flex'} justifyContent={'center'}>
                        <img style={{ height: '400px' }} src={iphone}/>
                    </Box>
                    <Box mt={{ xs: 2, sm: 4, md: 4 }} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-start'}>
                        <Box>
                            <Typography sx={{ color: 'white' }} textAlign={'center'} variant={'h4'}>
                                <strong>{languageValues.chargeWithUs}</strong>
                            </Typography>
                        </Box>
                        <Box mt={3}>
                            <Typography sx={{ color: 'white' }} textAlign={'center'}>
                                {languageValues.downloadOurApp}
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <Typography sx={{ color: 'white' }} textAlign={'center'}>
                                {languageValues.chargeWithApp}
                            </Typography>
                        </Box>
                        <Button onClick={handleOpenInstruction} variant={'outlined'} sx={{ color: 'white', fontWeight: 'bold', marginTop: '10px', borderColor: 'white' }}>Видеоинструкция</Button>
                        <Box mt={4} sx={{ cursor: 'pointer' }} onClick={() => openInNewTab('https://apps.apple.com/kz/app/evs-kz/id6451469541')}>
                            <img width={250} src={appstore}/>
                        </Box>
                        <Box mt={2} sx={{ cursor: 'pointer' }} onClick={() => openInNewTab('https://play.google.com/store/apps/details?id=kz.evs.ccms')}>
                            <img width={250} src={google}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

const InstructionDialog = ({ open, handleClose }) => {
    return (
        <Dialog sx={{ display: 'flex', justifyContent: 'center' }} fullWidth open={open} onClose={handleClose}>
            <CardMedia
                style={{ height: '600px', width: '300px' }}
                component='iframe'
                title='test'
                src={instruction}
            />
            <Close sx={{ color: 'white', top: 7, right: 15 }}/>
        </Dialog>
    )
}

export default Download;