import {connect} from "react-redux";
import React from 'react';
import ChargersMap from "./ChargersMap";
import {getChargers} from "../../redux/chargers-reducer";

const ChargersMapContainer = React.memo((props) => {
    return (
        <ChargersMap { ...props } />
    )
})

const mapStateToProps = (state) => {
    return {
        chargers: state.chargers.chargers
    };
}


export default connect(mapStateToProps, { getChargers })(ChargersMapContainer);
