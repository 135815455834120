import {Box, Container, Tab, Tabs, Typography} from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import {ImportContacts, Info} from "@mui/icons-material";
import {useState} from "react";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Box role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}{...other}>
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

const UserInfo = () => {

    const [tabValue, setTabValue] = useState(0)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Container p={4}>
            <Box mb={2} sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <ImportContacts sx={{ width: { xs: '30px', sm: '50px' }, height: { xs: '30px', sm: '50px' } }}/>
                <Typography sx={{ marginLeft: '15px', fontSize: { xs: '24px' } }} variant={'h4'}>Пользователям</Typography>
            </Box>
            <Box display={{ xs: 'none', md: 'flex' }} justifyContent={'center'}>
                <Tabs orientation={'horizontal'} sx={{ width: '100%' }} value={tabValue} onChange={handleTabChange} variant={'fullWidth'}>
                    <Tab label={'Публичная оферта'}/>
                    <Tab label={'Пользовательское соглашение'}/>
                    <Tab label={'Политика конфиденциальности'}/>
                </Tabs>
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} justifyContent={'center'}>
                <Tabs orientation={'vertical'} sx={{ width: '100%' }} value={tabValue} onChange={handleTabChange} variant={'fullWidth'}>
                    <Tab label={'Публичная оферта'}/>
                    <Tab label={'Пользовательское соглашение'}/>
                    <Tab label={'Политика конфиденциальности'}/>
                </Tabs>
            </Box>
            <Box>
                <TabPanel index={0} value={tabValue}>
                    <Typography>
                        <Typography align={'center'}><strong>ДОГОВОР ОКАЗАНИЯ УСЛУГ (ПУБЛИЧНАЯ ОФЕРТА)</strong></Typography>
                        <Typography align={'center'}>город Алматы, 2023 год</Typography>
                        <p>Исполнитель по Договору оказания услуг &ndash; ТОО &laquo;EV Solutions&raquo;, БИН 230640017092, Заказчик &ndash; физическое лицо, собственник/доверенное лицо собственника транспортного средства (далее &ndash; ТС), осуществляющее акцепт оферты и становящееся Заказчиком по данному Договору после принятия оферты. Договор считается заключенным в г. Алматы.</p>
                        <p>Перед тем, как принять условия Договора (публичной оферты), прочитайте внимательно все условия Договора, в том числе приложений к нему. Если у Вас есть какие-либо возражения, сомнения, вопросы по условиям Договора (публичной оферты) не принимайте условия настоящего Договора. В таком случае Исполнитель предлагает Вам отказаться от заключения Договора и использования услуг Исполнителя.</p>
                        <p>Настоящий Договор об оказании услуг по организации зарядки электрического и/или гибридного транспорта в городе Алматы (далее &ndash; Договор) на приведенных ниже условиях является публичной офертой ТОО &laquo;EV Solutions&raquo; в отношении Заказчика, для которого предоставляется площадь, на которой осуществляется процесс зарядки ТС (далее &ndash; Площадка). Настоящий Договор (публичная оферта) является официальным документом.</p>
                        <ol>
                            <li><strong> ПРЕДМЕТ ДОГОВОРА.</strong></li>
                        </ol>
                        <p>1.1. Исполнитель обязуется по заданию Заказчика оказать услуги, а Заказчик обязуется их оплатить. Исполнитель и Заказчик являются Сторонами по Договору.</p>
                        <p>1.2. Услуга Исполнителя по настоящему Договору представляет собой предоставление Исполнителем Заказчику услуги пользования зарядным устройством для зарядки ТС на возмездной основе.</p>
                        <p>Объем и сроки оказания Услуг указаны в Приложениях к Договору. Услуги оказываются на основании Заявки Заказчика, оформленной надлежащим образом, в мобильном приложении Исполнителя evs.kz.</p>
                        <ol start="2">
                            <li><strong> ПРАВА И ОБЯЗАННОСТИ ИСПОЛНИТЕЛЯ</strong></li>
                        </ol>
                        <p>2.1. Исполнитель обязуется:</p>
                        <p>2.1.1. Своими силами и за свой счет организовать и предоставить Заказчику Площадку, оборудованную всем необходимым оборудованием, для зарядки ТС, включая:</p>
                        <ul>
                            <li>Зарядное устройство с коннектором и/или без такового, которое подключено к электрической сети и выдает напряжение, достаточное для зарядки ТС Заказчика (далее &ndash; ЗУ).</li>
                            <li>специализированные помещения и площадки, соответствующие технике безопасности;</li>
                            <li>необходимые меры безопасности жизни и здоровья Заказчика при соблюдении последним техники безопасности;</li>
                        </ul>
                        <p>2.1.2. Требовать от Заказчика ознакомления с правилами пожарной безопасности, внутренним распорядком Площадки и их соблюдения;</p>
                        <p>2.1.3. Организовать готовность Площадки к приему ТС для проведения процесса зарядки.</p>
                        <p>2.1.4. Обеспечивать чистоту и надлежащее содержание Площадок и ЗУ.</p>
                        <p>2.2. Исполнитель имеет право:</p>
                        <p>2.2.1 На свое усмотрение, изменять мощность ЗУ на Площадке, руководствуясь техническими особенностями ЗУ;</p>
                        <p>2.2.2 От своего имени и за свой счет заключать договора с третьими лицами во исполнение обязанностей по настоящему Договору. При этом ответственность за действия третьих лиц в полном объеме несет Исполнитель;</p>
                        <p>2.2.3 При отказе Заказчика от акцепта настоящего Договора, не допускать (не принимать) ТС Заказчика на Площадку, налагать штрафы и пени, согласно установленному Заказчиком тарифу;</p>
                        <p>2.2.4 Определять количество ТС, одновременно допускаемых на Площадку;</p>
                        <p>2.2.5 В одностороннем порядке отказать Заказчику в оказании услуг по Договору по своему усмотрению, с возвратом ранее списанных денежных средств;</p>
                        <p>2.2.6. Досрочно прервать пребывание ТС Заказчика на Площадке (в том числе с привлечением специально оборудованных средств и представителей государственных органов), с последующим досрочным расторжением Договора. Оплаченная Заказчиком стоимость услуг возвращается на карт-счет Заказчика, с которого производилась оплата, за вычетом фактического времени пребывания ТС на Площадке и ущерба (в случае наличия такового).</p>
                        <p>2.2.7. Контролировать порядок оказания услуг.</p>
                        <p>2.2.8. Изменять места расположения Площадок с их нанесением на карты на сайте www.evs.kz и в мобильном приложении Исполнителя;</p>
                        <p>2.2.9. В одностороннем порядке изменить объем и сроки оказания услуг.</p>
                        <p>2.3. Исполнитель не несет ответственность за:</p>
                        <p>2.3.1. Состояние ТС Заказчика, в том числе если ТС заказчика будет повреждено на Площадке Исполнителя в процессе исполнения настоящего Договора;</p>
                        <p>2.3.2 Ценные вещи Заказчика, в том числе, если ценные вещи были оставлены в ТС Заказчика на Площадке;</p>
                        <p>2.3.3. Жизнь и здоровье Заказчика, процессе исполнения настоящего Договора, в том числе в случая, когда Заказчик получил какие либо травмы и/или увечья в процессе зарядки ТС пользуясь оборудованием Исполнителя.</p>
                        <p><strong>ПРАВА И ОБЯЗАННОСТИ ЗАКАЗЧИКА</strong></p>
                        <p>2.4. Заказчик обязуется:</p>
                        <p>2.4.1 Оплатить стоимость услуг в размере, сроке и порядке, указанном в разделе 3 Договора, а также пени и штрафов, в случае возникновения таковых;</p>
                        <p>2.4.2. Высадить из транспортного средства всех пассажиров до заезда на Площадку и в период осуществления зарядки ТС обеспечить отсутствие людей в ТС;</p>
                        <p>2.4.3. Ознакомиться с Правилами пребывания на Площадке, утвержденными Исполнителем и/или собственником/арендодателем места организации Площадки;</p>
                        <p>2.4.4. Возместить ущерб Исполнителя в соответствии с законодательством Республики Казахстан, в случае нанесения Заказчиком и/или пассажирами ТС Заказчика ущерба имуществу Исполнителя, Площадке и/или третьим лицам;</p>
                        <p>2.4.5. Компенсировать Исполнителю стоимость затрат, возникших у Исполнителя ввиду неисполнения и/или ненадлежащего исполнения Заказчиком условий Договора;</p>
                        <p>2.4.6. Не занимать Площадку, зарезервированную другим Заказчиком посредством мобильного приложения Исполнителя.</p>
                        <ul>
                            <li>После окончания зарядки ТС, отключить ТС от ЗУ и освободить Площадку в течение 5 (пяти) минут;</li>
                            <li>Самовольное занятие Площадки Исполнителя вне процесса зарядки ТС тарифицируется в тройном размере от тарифа (Штраф);</li>
                        </ul>
                        <p>2.4.7. Прибыть на Площадку не позднее 10 (десяти) минут по окончании срока, согласованного Сторонами в мобильном приложении (резервирование). В случае задержки прибытия ТС Заказчика на Площадку на срок менее 10 (десяти) минут, время ожидания Исполнителя тарифицируется согласно тарифам Исполнителя.</p>
                        <p>В случае задержки прибытия Заказчика на срок более 10 (десяти) минут от оговоренного времени резервирования, стоимость услуг в размере 100% Заказчику не возвращается и удерживается Исполнителем в качестве штрафа, а Площадка предоставляется следующему Заказчику в порядке очередности.</p>
                        <p>2.6. Заказчик имеет право:</p>
                        <p>2.6.1. Требовать возврата на карт-счет неиспользованной части ранее списанных с него денежных средств за исключением случаев, предусмотренных настоящим Договором;</p>
                        <p>2.6.2. Требовать от Исполнителя надлежащего исполнения условий Договора.</p>
                        <p>2.7. Заказчик не имеет права:</p>
                        <p>2.7.1. Вмешиваться в деятельность Исполнителя и/или процесс ТП, самостоятельно вносить в ТП изменения и/или требовать от Исполнителя любых изменений в программе ТП.</p>
                        <p>2.7.2. Занимать Площадку без произведения оплаты по настоящему Договору.</p>
                        <p>2.8. Заказчик несет ответственность:</p>
                        <p>2.8.1. В случае нанесения ущерба действиями/бездействиями, нарушения правил технической безопасности на Площадке, а также Правил пребывания на Площадке, утвержденных Исполнителем и/или собственником/арендодателем места расположения Площадки.</p>
                        <ol start="3">
                            <li><strong> ЦЕНА И ПОРЯДОК РАСЧЕТОВ</strong></li>
                        </ol>
                        <p>3.1. Стоимость оказываемых Исполнителем услуг по настоящему Договору указывается в мобильном приложении Исполнителя.</p>
                        <p>3.2. Оплата по настоящему Договору осуществляется Заказчиком в тенге согласно тарифам Исполнителя. Датой оплаты считается дата зачисления денежных средств на расчетный счет Исполнителя. Все банковские сборы, в том числе сборы банков- посредников, оплачивает Заказчик.</p>
                        <p>3.3. Самовольное занятие Площадки Исполнителя вне процесса зарядки ТС тарифицируется в тройном размере от тарифа Исполнителя и оплачивается путем безакцептного списания с карт-счета (электронного кошелька в мобильном приложении Исполнителя) Заказчика, с которого производилась оплата услуг Исполнителя (штраф).</p>
                        <ol start="4">
                            <li><strong> ОТВЕТСТВЕННОСТЬ СТОРОН</strong></li>
                        </ol>
                        <p>4.1. За неисполнение или ненадлежащее исполнение своих обязательств по настоящему Договору Стороны несут ответственность в соответствии с законодательством РК.</p>
                        <p>4.2. В случае отказа Заказчика от услуг, указанных в п.1.1. настоящего Договора и/или в случае неприбытия на Площадку в срок, согласованный Сторонами и оговоренные в подпунктах 2.4.6., 2.4.7. Договора (нарушение Заказчиком сроков резервирования), оплаченная стоимость услуг в размере 100% Заказчику не возвращается и удерживается Исполнителем в качестве штрафа.</p>
                        <p>4.3. В случае отказа Заказчика от услуг, не менее чем за 30 (тридцать) минут до времени, указанного Заказчиком при резервировании Площадки, списанная с карт-счета (электронного кошелька в мобильном приложении Исполнителя) Заказчика сумма возвращается Исполнителем на карт-счет (электронный кошелек в мобильном приложении Исполнителя) Заказчика в течение 10 (десяти) банковских дней.</p>
                        <p>4.4. В случае отказа Исполнителя от оказания услуг в одностороннем порядке ввиду неготовности Площадки к приему ТС Заказчика, Исполнитель обязуется вернуть Заказчику уплаченную сумму в течение 10 (десяти) банковских дней, с даты такого отказа.</p>
                        <p>4.5. В случаях нарушения Заказчиком условий настоящего Договора, Исполнитель вправе в одностороннем порядке в любое время расторгнуть настоящий Договор, не возвращая оплаченных денежных средств и потребовать от Заказчика возмещения убытков.</p>
                        <ol start="5">
                            <li><strong> ЗАКЛЮЧЕНИЕ ДОГОВОРА. СРОК ДЕЙСТВИЯ ДОГОВОРА</strong></li>
                        </ol>
                        <p>5.1. Настоящий Договор заключен путем акцепта Заказчиком публичной оферты Исполнителя и действует до полного исполнения Сторонами принятых на себя обязательств.</p>
                        <p>Исполнитель оставляет за собой право внести изменения в условия оферты или отозвать оферту в любой момент по своему усмотрению, при обязательном уведомлении Заказчика в срок, не позднее 5 (пяти) рабочих дней до момента вступления в силу изменений или отзыва оферты. В случае внесения Исполнителем изменений в оферту, такие изменения вступают в силу с момента их опубликования на сайте Исполнителя www.evs.kz и/или в мобильном приложении Исполнителя.</p>
                        <p>5.2. Заказчик соглашается и признает, что внесение изменений в оферту влечет за собой внесение этих изменений в заключенный и действующий между Сторонами Договор, и эти изменения в Договор вступают в силу одновременно с вступлением в силу таких изменений в оферте.</p>
                        <p>5.3. Настоящим Заказчик подтверждает, что обладает всеми законными правами и полномочиями, необходимыми для заключения Договора.</p>
                        <p>5.4. Исполнитель оставляет за собой право в любое время вносить изменения в условия настоящего Договора (публичной оферты). Если время вступления изменений в силу специально не оговорено, они начинают свое действие с момента (день/час/минуты) опубликования их на веб-сайте Исполнителяwww.evs.kz и/или в мобильном приложении Исполнителя. Оформление Заказчиком услуг после внесения изменений в Договор (в публичную оферту) однозначно понимается Исполнителем и Заказчиком как согласие с внесенными изменениями.</p>
                        <ol start="6">
                            <li><strong> ФОРС-МАЖОР</strong></li>
                        </ol>
                        <p>6.1. Стороны освобождаются от ответственности за полное или частичное неисполнение какого- либо из обязательств вследствие наступления обстоятельств непреодолимой силы, таких как: наводнение, пожар, землетрясение, а также в случае войны и военных действий или запретов компетентных государственных органов, возникших после заключения настоящего Договора.</p>
                        <p>6.2. Сторона, которая не в состоянии выполнить свои обязательства по причинам форс- мажорных обстоятельств, должна в письменной форме незамедлительно уведомить другую Сторону о начале, ожидаемом сроке действия и прекращения указанных обстоятельств. Факты, содержащиеся в уведомлении, должны быть подтверждены Торговой Палатой или другой компетентной организацией соответствующей Стороны. Не уведомление или несвоевременное уведомление лишает виновную сторону права на освобождение от обязательств вследствие указанных обстоятельств.</p>
                        <p>6.3. Если невозможность полного или частичного выполнения обязательств для одной из Сторон длится более 2 (двух) месяцев, другая Сторона имеет право полностью или частично аннулировать настоящий Договор без обязательств о возмещении возможного ущерба Стороне, у которой возникли форс-мажорные обстоятельства, но с проведением взаиморасчетов.</p>
                        <ol start="7">
                            <li><strong> ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ. ПРИМЕНИМОЕ ПРАВО</strong></li>
                        </ol>
                        <p>7.1. При возникновении споров и разногласий Стороны приложат все усилия для их разрешения путем переговоров, с соблюдением претензионного порядка урегулирования споров (срок ответа на претензию &ndash; 10 (десять) рабочих дней). В случае если согласие не будет достигнуто, споры подлежат рассмотрению в судебном порядке в соответствии с действующим законодательством РК, в судебных органах по месту нахождения Исполнителя (договорная подсудность).</p>
                        <p>7.2. Во всем остальном, что не предусмотрено настоящим Договором, Стороны руководствуются нормами действующего законодательства РК.</p>
                        <p>7.3. Настоящим Заказчик подтверждает, что условия Договора (условия оферты) им прочитаны, все условия Договора (оферты) понятны, он с ними согласен, полностью и безоговорочно принимает их.</p>
                        <p>7.4. Вся переписка по настоящему Договору осуществляется Сторонами строго по реквизитам, указанным в п. 8 Договора и в Заявке.</p>
                        <p>7.5. Передача Заказчиком своих прав/обязанностей по настоящему Договору третьим лицам допускается только после получения Заказчиком на это письменного согласия Исполнителя.</p>
                        <p>7.6. Исполнитель вправе по своему усмотрению полностью или частично передавать права и/или обязанности по настоящему Договору третьим лицам.</p>
                        <p>7.7. Настоящий Договор составлен на русском языке.</p>
                        <p>7.8. Приложения к Договору:</p>
                        <p>Приложение No1 - Пользовательское соглашение для мобильного приложения зарядки электроавтомобиля. Приложение No2 - Политика конфиденциальности</p>
                        <ol start="8">
                            <li><strong> АДРЕС, БАНКОВСКИЕ РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ </strong></li>
                        </ol>
                        <p>Исполнитель</p>
                        <p>ТОО &laquo;EV Solutions&raquo;</p>
                        <p>Юридический адрес:</p>
                        <p>г.Алматы, ул.Ураза Исаева 15, п.78</p>
                        <p>БИН 230640017092</p>
                        <p>Реквизиты АО&laquo;Банк ЦентрКредит&raquo;:</p>
                        <p>БИК (SWIFT) KCJBKZKX</p>
                        <p>KZ368562203130887635 (KZT)</p>
                        <p>БИН 981141000668</p>
                        <p>050010, КАЗАХСТАН. АЛМАТЫ, МЕДЕУСКИЙ</p>
                        <p>р-и, НУРСУЛТАН НАЗАРБАЕВ пр-кт, дом 26</p>
                        <p>тел.: +77273783293</p>
                        <p>email.:info@evs.kz</p>
                        <p>в лице Директора Разбекова Т.Т., действующего в на основании Устава.</p>
                    </Typography>
                </TabPanel>
                <TabPanel index={1} value={tabValue}>
                    <Typography>
                        <Typography sx={{ textTransform: 'upperCase' }} align={'center'}><strong><span>Пользовательское соглашение для мобильного приложения зарядки электроавтомобиля</span></strong></Typography>
                        <p><strong>1. Введение</strong></p>
                        <p>1.2. Данное Пользовательское соглашение (далее - "Соглашение") описывает условия использования мобильного приложения зарядки электроавтомобиля (далее - "Приложение") и регулирует отношения между вами (далее - "Пользователь") и разработчиком Приложения (далее - "Разработчик"). При использовании Приложения вы соглашаетесь с условиями данного Соглашения. В случае несогласия с условиями, пожалуйста, прекратите использование Приложения.</p>
                        <p><strong>2. Определения</strong></p>
                        <p>2.1. "Приложение" - мобильное приложение зарядки электроавтомобиля, разработанное Разработчиком и предоставляемое для использования Пользователем.</p>
                        <p>2.2. "Пользователь" - физическое или юридическое лицо, использующее Приложение в соответствии с условиями данного Соглашения.</p>
                        <p>2.3. "Разработчик" - юридическое лицо или физическое лицо, являющееся владельцем и разработчиком Приложения.</p>
                        <p><strong>3. Права и обязанности пользователя</strong></p>
                        <p>3.1. Пользователь имеет право использовать Приложение с целью зарядки электроавтомобилей, в соответствии с его функциональностью и указаниями Разработчика.</p>
                        <p>3.2. Пользователь обязуется использовать Приложение только в соответствии с действующим законодательством и настоящим Соглашением. Запрещается использование Приложения для незаконных целей или совершения действий, которые могут нарушить права третьих лиц.</p>
                        <p>3.3. Пользователь несет полную ответственность за все действия, совершаемые им при использовании Приложения, включая использование информации, предоставляемой в Приложении.</p>
                        <p>4. Политика конфиденциальности и обработка персональных данных</p>
                        <p>4.1. При использовании Приложения, Пользователь предоставляет определенную персональную информацию Разработчику. Сбор, использование и хранение персональных данных Пользователя регулируются политикой конфиденциальности, доступной по [вставьте ссылку на политику конфиденциальности].</p>
                        <p><strong>5. Платные услуги</strong></p>
                        <p>5.1. Приложение может предоставлять платные услуги, такие как услуги зарядки электроавтомобиля, согласно тарифам и условиям, определенным Разработчиком.</p>
                        <p>Информация о возможных штрафах и пенях при пользовании приложением отражена в Договоре*</p>
                        <p><strong>6. Правила отказа от услуги и удаления аккаунта</strong></p>
                        <p>6.1. Пользователь вправе отказаться от использования Приложения в любое время и удалить свой аккаунт. При этом, информация, связанная с аккаунтом пользователя, может быть удалена в соответствии с политикой конфиденциальности.</p>
                        <p><strong>7. Урегулирование конфликтов в судебном порядке</strong></p>
                        <p>7.1. В случае возникновения споров, связанных с использованием Приложения, стороны стремятся решить их путем переговоров и консультаций. Если споры не могут быть разрешены путем переговоров, они могут быть поданы в суд в соответствии с действующим законодательством.</p>
                        <p><strong>8. Отказ от ответственности или её ограничение</strong></p>
                        <p>8.1. Разработчик Приложения не несет ответственности за любые прямые или косвенные убытки, понесенные Пользователем в результате использования или невозможности использования Приложения.</p>
                        <p>8.2. Пользователь осознает, что Приложение предоставляется "как есть" и Разработчик не дает никаких явных или подразумеваемых гарантий относительно его работы или соответствия конкретным потребностям Пользователя.</p>
                        <p><strong>9. Возможность изменения условий использования</strong></p>
                        <p>9.1. Разработчик оставляет за собой право вносить изменения в условия данного Соглашения. Изменения вступают в силу с момента их публикации в Приложении или иным удобным для Разработчика способом.</p>
                        <p>9.2. Разработчик обязуется уведомлять Пользователей о любых изменениях в условиях использования путем отправки уведомлений на зарегистрированные адреса электронной почты или другими доступными способами.</p>
                    </Typography>
                </TabPanel>
                <TabPanel index={2} value={tabValue}>
                    <Typography>
                        <Typography align={'center'} textTransform={'upperCase'}><strong><span>Политика конфиденциальности</span></strong></Typography>
                        <p>Настоящая Политика конфиденциальности описывает, как мы собираем, используем и защищаем вашу личную информацию в связи с использованием мобильного приложения зарядки электроавтомобиля (далее - "Приложение"). Пожалуйста, внимательно ознакомьтесь с данным документом, чтобы понять нашу практику обработки данных и вашу конфиденциальность.</p>
                        <p><strong>1. Собираемая информация</strong></p>
                        <p>Мы собираем следующую личную информацию о пользователях Приложения:</p>
                        <p>- ФИО</p>
                        <p>- Адрес электронной почты</p>
                        <p>- Номер телефона</p>
                        <p>- IP-адрес</p>
                        <p>- Данные о зарядке электроавтомобиля</p>
                        <p><strong>2. Использование и обработка информации</strong></p>
                        <p>Мы используем предоставленную личную информацию для следующих целей:</p>
                        <p>- Предоставление услуг зарядки электроавтомобиля и поддержки пользователей</p>
                        <p>- Определение местоположения для предоставления ближайших пунктов зарядки</p>
                        <p>- Улучшение функциональности и оптимизации Приложения</p>
                        <p>- Обеспечение безопасности и предотвращение мошенничества</p>
                        <p>- Уведомление о новостях, акциях и обновлениях Приложения (при наличии вашего согласия)</p>
                        <p><strong>3. Защита информации</strong></p>
                        <p>Мы предпринимаем необходимые меры безопасности для защиты вашей личной информации от несанкционированного доступа, использования или раскрытия. Мы применяем технические, административные и физические меры для обеспечения безопасности данных.</p>
                        <p><strong>4. Хранение информации</strong></p>
                        <p>Мы храним вашу личную информацию в течение необходимого периода для достижения целей, описанных в данной Политике конфиденциальности. Логи хранятся в течение 3 месяцев, если иное не требуется законом или для обеспечения законных интересов.</p>
                        <p><strong>5. Платежные данные</strong></p>
                        <p>Мы сохраняем информацию о платежах, такую как сумма платежей, однако мы не сохраняем данные вашей карты. Обработка платежей осуществляется через надежные и сертифицированные платежные системы, соблюдая соответствующие стандарты безопасности.</p>
                        <p><strong>6. Передача информации третьим лицам</strong></p>
                        <p>Мы не передаем вашу личную информацию третьим лицам или сторонним сервисам без вашего предварительного согласия, за исключением случаев, предусмотренных законом или когда это необходимо для выполнения услуг, предоставляемых через Приложение.</p>
                        <p><strong>7. Ваши права</strong></p>
                        <p>Вы имеете право управлять своей личной информацией. Вы можете обновлять, исправлять или удалять предоставленные данные, а также отозвать свое согласие на обработку информации. Вы также можете запросить доступ к вашим личным данным, чтобы убедиться в их точности и целостности.</p>
                        <p><strong>8. Изменения в политике конфиденциальности</strong></p>
                        <p>Мы оставляем за собой право вносить изменения в данную Политику конфиденциальности. Любые изменения будут опубликованы в Приложении, и мы рекомендуем периодически проверять обновления. Продолжая использовать Приложение после внесения изменений, вы соглашаетесь с обновленной Политикой конфиденциальности.</p>
                        <p>Если у вас есть вопросы или требуется помощь в отношении политики конфиденциальности, пожалуйста, свяжитесь с нами по следующим контактным данным: г.Алматы, ул. Ураза Исаева 15, н.п.78. Тел.: +7 727 378 32 93; e-mail: info@evs.kz</p>
                    </Typography>
                </TabPanel>
            </Box>
        </Container>
    )
}

export default UserInfo;