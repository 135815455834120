import {chargersAPI} from "../api/api";

const SET_CHARGERS = 'SET_CHARGERS'

let initialState = {
    chargers: []
}

const chargersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHARGERS:
            return { ...state, chargers: action.chargers }
        default:
            return state;
    }
}

const setChargers = (chargers) => ({ type: SET_CHARGERS, chargers })


export const getChargers = () => async (dispatch) => {
    let response = await chargersAPI.getChargers();
    dispatch(setChargers(response))
}




export default chargersReducer;