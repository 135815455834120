import './App.css';
import MainContainer from "./components/Main/MainContainer";
import store from "./redux/redux-store";
import {Provider} from "react-redux";
import {createTheme, ThemeProvider, Typography} from "@mui/material";
import {blue, green} from "@mui/material/colors";
import {BrowserRouter, HashRouter} from 'react-router-dom';

const theme = createTheme({
    palette: {
        primary: {
            main: green[500],
        },
        secondary: {
            main: blue[500],
        },
    },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <HashRouter>
                <MainContainer/>
            </HashRouter>
        </Provider>
    </ThemeProvider>
  );
}

export default App;
