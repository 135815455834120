import {Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography} from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";

const Faq = ({ language, languageValues }) => {


    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <Container>
            <Box sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <QuestionAnswerIcon sx={{ width: { xs: '30px', sm: '50px' }, height: { xs: '30px', sm: '50px' } }}/>
                <Typography sx={{ marginLeft: '10px', fontSize: { xs: '24px' } }} variant={'h4'}>{languageValues?.faqHeader}</Typography>
            </Box>
            <Box pt={2}>
                {languageValues?.faq.map((x, index) =>
                    <Accordion expanded={expanded === x.question} onChange={handleChange(x.question)} elevation={1} sx={{ padding: '10px' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>{index + 1}. {x.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{x.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
            </Box>
        </Container>
    )
}

export default Faq;