import {Box, Button, Container, Grid, Typography} from "@mui/material";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import logo from "../../images/contact-us.jpg";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {Checklist, LocationOn} from "@mui/icons-material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import station from '../../images/hotel-alatau.jpg'

const Products = ({language, languageValues, handleOpenSendRequest}) => {
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };
    const openLink = (url) => {
        window.open(url);
    };

    const products = [
        {
            id: 1,
            name: 'EVS DC 120KW GBT/GBT',
            description: 'Быстрая станция зарядки электромобилей EVS DC 120KW GBT/GBT обеспечивает эффективное зарядное решение для вашего электромобиля. Совместимость с кабелем GBT обеспечивает удобство и надежность зарядки. Мощность: 120 кВт.',
            imageUrl: 'https://via.placeholder.com/150',
            price: '5 471 232 тг.'
        },
        {
            id: 4,
            name: 'EVS DC 120KW GBT/CCS2',
            description: 'Станция зарядки EVS DC 120KW GBT (GBT) / CCS2 обеспечивает высокую скорость зарядки для вашего электромобиля. Один коннектор GBT и один коннектор CCS2 обеспечивают универсальность в использовании, подходящую для широкого спектра электромобилей. ',
            imageUrl: 'https://via.placeholder.com/150',
            price: '5 945 772 тг.'
        },
        {
            id: 2,
            name: 'EVS DC 160KW GBT/GBT',
            description: 'Станция зарядки EVS DC 160KW GBT/GBT предлагает высокоскоростное зарядное решение для вашего электромобиля с кабелем GBT. Благодаря этой станции ваш электромобиль будет готов к новым приключениям за минимальное время. Мощность: 160 кВт.',
            imageUrl: 'https://via.placeholder.com/150',
            price: '6 314 780 тг.'
        },
        {
            id: 6,
            name: 'EVS DC 160KW GBT/CCS2',
            description: 'Станция зарядки EVS DC 160KW GBT (GBT) / CCS2 обеспечивает быструю и эффективную зарядку вашего электромобиля. Один коннектор GBT и один коннектор CCS2 обеспечивают гибкость и удобство при зарядке. Мощность: 160 кВт.',
            imageUrl: 'https://via.placeholder.com/150',
            price: '6 794 212 тг.'
        },
        {
            id: 3,
            name: 'EVS DC 80KW GBT/GBT',
            description: 'Станция зарядки EVS DC 80KW GBT/GBT предлагает надежное и быстрое зарядное решение для вашего электромобиля с кабелем GBT. Идеально подходит для зарядки в течение коротких периодов времени. Мощность: 80 кВт.',
            imageUrl: 'https://via.placeholder.com/150',
            price: '4 982 016 тг.'
        },
        {
            id: 5,
            name: 'EVS DC 80KW GBT/CCS2',
            description: 'Станция зарядки EVS DC 80KW GBT (GBT) / CCS2 обеспечивает удобную и быструю зарядку вашего электромобиля. Один коннектор GBT и один коннектор CCS2 обеспечивают универсальность и подходят для различных типов электромобилей. Мощность: 80 кВт.',
            imageUrl: 'https://via.placeholder.com/150',
            price: '5 226 624 тг.'
        },
        // Добавьте другие товары по аналогии
    ];

    return (
        <Container p={4}>
            <Box mb={2} sx={{marginTop: '20px', display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                <Checklist sx={{width: {xs: '30px', sm: '50px'}, height: {xs: '30px', sm: '50px'}}}/>
                <Typography sx={{marginLeft: '15px', fontSize: {xs: '24px'}}} variant={'h4'}>Наша продукция</Typography>
            </Box>
            <Grid container spacing={2}>
                {products.map((product) => (
                    <Grid item xs={12} sm={6} md={4} key={product.id}>
                        <Card>
                            <CardContent>
                                <div
                                    style={{

                                    }}
                                >
                                    <img style={{ borderRadius: 10, width: '100%' }} src={station}/>
                                </div>
                                <Typography variant="h6" component="div" sx={{mt: 2}}>
                                    {product.name}
                                </Typography>
                                <Typography component="div">
                                    {product.price}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{mt: 1, height: '150px' }}>
                                    {product.description}
                                </Typography>
                                <Button
                                    variant="contained"
                                    onClick={handleOpenSendRequest}
                                    sx={{mt: 2, color: 'white'}}
                                >
                                    Отправить запрос
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}

export default Products;