import {Box, Container, Grid, Typography} from "@mui/material";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import {
    CheckCircle,
    Checklist,
    ChecklistOutlined,
    ChecklistSharp,
    DoneAll,
    DoneOutlined,
    DoneSharp, Handshake,
    Info
} from "@mui/icons-material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import HandshakeIcon from '@mui/icons-material/Handshake';
import logo from '../../images/evs-logo.png'
import grasen from '../../images/about/grasen-logo.png'
import certificate from '../../images/about/certificate.png'
import signature from '../../images/about/signature.jpg'
import factory2 from '../../images/about/factory2.jpg'
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";
import chargerFeatures from '../../images/about/charger-features.JPG'
import conference from '../../images/about/conference.JPG'
import engineers from '../../images/about/engineers.JPG'
import factory from '../../images/about/factory.JPG'
import gm from '../../images/about/gm.JPG'
import partnership from '../../images/about/partnership.jpg'
import president from '../../images/about/president.JPG'
import stations from '../../images/about/stations.JPG'

const AboutUs = () => {
    const columns = [
        {
            title: 'Экологическая ответственность',
            description:'Мы верим, что электротранспорт - это будущее, и его развитие необходимо для борьбы с изменением климата и сокращения выбросов вредных веществ. Мы стремимся создать экологически чистую транспортную систему в Казахстане.',
            icon: <EnergySavingsLeafIcon sx={{ color: 'green', width: '50px', height: '50px' }}/>
        },
        {
            title: 'Качество обслуживания',
            description: 'Мы придаем огромное значение качеству нашей работы и обслуживания клиентов. Мы гарантируем надежность, эффективность и оперативность наших зарядных станций, а также предоставляем высококлассную поддержку пользователям.',
            icon: <ThumbUpAltIcon sx={{ color: 'green', width: '50px', height: '50px' }}/>
        },
        {
            title: 'Инновации',
            description:'Мы активно следим за последними технологическими разработками в области электротранспорта и зарядных станций. Мы стремимся постоянно внедрять новые решения и улучшать нашу сеть, чтобы удовлетворить потребности наших клиентов.',
            icon: <TipsAndUpdatesIcon sx={{ color: 'green', width: '50px', height: '50px' }}/>
        },
        {
            title: 'Партнерство',
            description:'Мы ценим партнерство с другими компаниями, государственными органами и обществом в целом. Мы стремимся к сотрудничеству и взаимовыгодному партнерству с целью развития электромобильной инфраструктуры в Казахстане.',
            icon: <HandshakeIcon sx={{ color: 'green', width: '50px', height: '50px' }}/>
        },
    ]
    const images = [
        { original: factory, thumbnail: factory },
        { original: factory2, thumbnail: factory },
        { original: engineers, thumbnail: engineers },
        { original: chargerFeatures, thumbnail: chargerFeatures },
        { original: signature, thumbnail: signature },
        { original: conference, thumbnail: conference },
        { original: gm, thumbnail: gm },
        { original: partnership, thumbnail: partnership },
        { original: president, thumbnail: president },
        { original: stations, thumbnail: stations },
    ]
    return (
        <Container p={4}>
            <Box mb={2} sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <Info sx={{ width: { xs: '30px', sm: '50px' }, height: { xs: '30px', sm: '50px' } }}/>
                <Typography sx={{ marginLeft: '15px', fontSize: { xs: '24px' } }} variant={'h4'}>О нас</Typography>
            </Box>
            <Box p={2}>
                <Box display={'flex'}>
                    <CheckCircle sx={{ color: 'green', marginRight: '15px', width: { xs: '30px', sm: '30px' }, height: { xs: '30px', sm: '30px' } }}/>
                    <Typography>Мы являемся новаторской компанией, специализирующейся на создании и обслуживании сети зарядных станций для электротранспорта в Казахстане. Наша компания была основана в 2023 году с целью привнести революционные изменения в мобильность и внедрить экологически чистые технологии в нашу страну.</Typography>
                </Box>
                <Box mt={3} display={'flex'}>
                    <CheckCircle sx={{ color: 'green', marginRight: '15px', width: { xs: '30px', sm: '30px' }, height: { xs: '30px', sm: '30px' } }}/>
                    <Typography>
                        Миссия нашей компании заключается в том, чтобы сделать зарядку электротранспорта максимально доступной и удобной для всех пользователей. Мы стремимся создать плотную сеть зарядных станций, охватывающую города, автомагистрали, парковки и другие места, где водители электромобилей могут безопасно и уверенно заряжать свои транспортные средства.
                    </Typography>
                </Box>
            </Box>
            <Box mb={2} sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <Handshake/>
                <Typography sx={{ textAlign: { xs: 'center', md: 'left' }, marginLeft: '15px', fontSize: { xs: '24px' } }} variant={'h5'}>Партнерство с GRASEN</Typography>
            </Box>
            <Box m={3}>
                <Box sx={{ display: { xs: 'none', lg: 'flex' } }} alignItems={'flex-start'}>
                    <img width={'50%'} src={certificate}/>
                    <Box>
                        <Box display={'flex'} pt={6} alignItems={'center'} flexDirection={'column'}>
                            <img src={logo} style={{ width: '300px' }}/>
                            <img src={grasen} style={{ width: '300px' }}/>
                        </Box>
                        <Box pl={4}>
                            <Box mt={3} display={'flex'}>
                                <CheckCircle sx={{ color: 'green', marginRight: '15px', width: { xs: '30px', sm: '30px' }, height: { xs: '30px', sm: '30px' } }}/>
                                <Typography>Мы являемся партнером GRASEN - одного из крупнейших производителей станций для зарядки электротранспорта в КНР</Typography>
                            </Box>
                            <Box mt={3} display={'flex'}>
                                <CheckCircle sx={{ color: 'green', marginRight: '15px', width: { xs: '30px', sm: '30px' }, height: { xs: '30px', sm: '30px' } }}/>
                                <Typography>Сотрудничество с GRASEN позволяет нам предлагать высококачественные решения для зарядки электромобилей, обеспечивая нашим клиентам надежность, эффективность и инновационные технологии. Наш опыт и экспертиза позволяют нам обеспечивать поддержку на всех этапах процесса, от консультации и выбора подходящего оборудования до установки и технической поддержки.</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box mb={3} sx={{ display: { xs: 'block', lg: 'none' } }} alignItems={'flex-start'}>
                    <img width={'100%'} src={certificate}/>
                    <Box>
                        <Box display={'flex'} pt={6} alignItems={'center'} flexDirection={'column'}>
                            <img src={logo} style={{ width: '300px' }}/>
                            <img src={grasen} style={{ width: '300px' }}/>
                        </Box>
                        <Box pl={4}>
                            <Box mt={3} display={'flex'}>
                                <CheckCircle sx={{ color: 'green', marginRight: '15px', width: { xs: '30px', sm: '30px' }, height: { xs: '30px', sm: '30px' } }}/>
                                <Typography>Мы являемся партнером GRASEN - одного из крупнейших производителей станций для зарядки электротранспорта в КНР</Typography>
                            </Box>
                            <Box mt={3} display={'flex'}>
                                <CheckCircle sx={{ color: 'green', marginRight: '15px', width: { xs: '30px', sm: '30px' }, height: { xs: '30px', sm: '30px' } }}/>
                                <Typography>Сотрудничество с GRASEN позволяет нам предлагать высококачественные решения для зарядки электромобилей, обеспечивая нашим клиентам надежность, эффективность и инновационные технологии. Наш опыт и экспертиза позволяют нам обеспечивать поддержку на всех этапах процесса, от консультации и выбора подходящего оборудования до установки и технической поддержки.</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <ImageGallery showNav={true} lazyLoad={true} items={images}/>
            </Box>
            <Box mb={2} sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <Checklist/>
                <Typography sx={{ textAlign: { xs: 'center', md: 'left' }, marginLeft: '15px', fontSize: { xs: '24px' } }} variant={'h5'}>Наши ценности</Typography>
            </Box>
            <Grid display={'flex'} flexDirection={{ xs: 'column', md: 'row' }}>
                {columns.map((x, index) =>
                    <Card sx={{ marginTop: { xs: '10px', md: 0 }, marginRight: index !== columns.length + 1 ? '10px' : 0 }}>
                        <CardMedia sx={{ marginTop: '10px', paddingLeft: '15px'}}>
                            {x.icon}
                        </CardMedia>
                        <CardContent>
                            <Typography>{x.title}</Typography>
                            <Typography  sx={{ paddingTop: '10px' }} align={'justify'} variant={'body2'}>
                                {x.description}
                            </Typography>
                        </CardContent>
                    </Card>
                )}
            </Grid>
        </Container>
    )
}

export default AboutUs;