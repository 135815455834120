import * as React from 'react';

class ChatwootWidget extends React.Component {
    componentDidMount () {
        // Add Chatwoot Settings
        window.chatwootSettings = {
            hideMessageBubble: false,
            position: 'right', // This can be left or right
            locale: 'ru', // Language to be set
            type: 'standard', // [standard, expanded_bubble]
        };

        // Paste the script from inbox settings except the <script> tag
        (function(d,t) {
            var BASE_URL="https://help.evs.kz";
            var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
            g.src=BASE_URL+"/packs/js/sdk.js";
            s.parentNode.insertBefore(g,s);
            g.async=!0;
            g.onload=function(){
                window.chatwootSDK.run({
                    websiteToken: 'wAkF4BjabE4APRkJ2h2UyYk3',
                    baseUrl: BASE_URL
                })
            }
        })(document,"script");
    }

    render () {
        return null;
    }
}

export default ChatwootWidget;