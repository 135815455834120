import {Box, Container, Stack, Typography} from "@mui/material";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import {useEffect} from "react";
import NewsItemCard from "./NewsItem";

const News = ({ news, getNews, language, languageValues }) => {

    useEffect(() => {
        getNews()
    }, [])

    return (
        <Container>
            <Box sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <NewspaperIcon sx={{ width: { xs: '30px', sm: '50px' }, height: { xs: '30px', sm: '50px' } }}/>
                <Typography sx={{ marginLeft: '10px', fontSize: { xs: '24px' } }} variant={'h4'}>{languageValues?.newsHeader}</Typography>
            </Box>
            <Stack direction={'row'} overflow={'scroll'} sx={{ '::-webkit-scrollbar': {
                    display: 'none'}, margin: '10px 0', padding: '10px 0' }}>
                {news.map(x => <NewsItemCard newsItem={x}/>)}
            </Stack>
        </Container>
    )
}

export default News;