import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {green, red} from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {ExpandMore} from "@mui/icons-material";
import {useState} from "react";
import almaty from '../../images/almaty.jpg'
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

const NewsItemCard = ({ newsItem }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const navigate = useNavigate();

    const file = newsItem.files.find(x => x.isMain === true);

    return (
        <Card sx={{ minWidth: 275, marginRight: '15px' }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
                        EVS
                    </Avatar>
                }
                title={<Typography sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                }}>{newsItem.title}</Typography>}
                subheader={new Date(Date.parse(newsItem.createdAt)).toLocaleDateString()}
            />
            {
                file &&
                <CardMedia
                    component="img"
                    height="194"
                    loading="lazy"
                    image={`https://yuku.kz/Images?fileId=${file.id}`}
                    alt="Almaty"
                />
            }
            <CardContent>
                <Typography sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "4",
                    WebkitBoxOrient: "vertical",
                }} variant="body2" color="text.secondary">
                    {newsItem.description}
                </Typography>
            </CardContent>
            <CardActions sx={{ marginBottom: '10px' }} disableSpacing>
                <Button onClick={() => navigate(`/news/${newsItem.id}`)} variant={'outlined'}>Подробнее</Button>
            </CardActions>
        </Card>
    )
}

export default NewsItemCard;