import {connect} from "react-redux";
import React from 'react';
import News from "./News";
import {getNews} from "../../redux/news-reducer";

const NewsContainer = React.memo((props) => {
    return (
        <News { ...props } />
    )
})

const mapStateToProps = (state) => {
    return {
        news: state.news.news
    };
}


export default connect(mapStateToProps, { getNews })(NewsContainer);
