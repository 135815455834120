import {connect} from "react-redux";
import React from 'react';
import OurServices from "./OurServices";

const OurServicesContainer = React.memo((props) => {
    return (
        <OurServices { ...props } />
    )
})

const mapStateToProps = (state) => {
    return {

    };
}


export default connect(mapStateToProps, { })(OurServicesContainer);
