import logo from '../../images/EVS-logo-aligned.png'
import HeaderContainer from "../Header/HeaderContainer";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button, ButtonGroup,
    Container, Dialog, DialogContent, DialogTitle, IconButton, ImageList,
    ImageListItem, ImageListItemBar, Tab, Tabs,
    Typography
} from "@mui/material";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BoltIcon from '@mui/icons-material/Bolt';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EvStationIcon from '@mui/icons-material/EvStation';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {useEffect, useState} from "react";
import appstore from '../../images/appstorelast.png'
import HandshakeIcon from '@mui/icons-material/Handshake';
import google from '../../images/googlelast.png'
import {Map, YMaps} from "@pbe/react-yandex-maps";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import iphone from '../../images/iphone-evs.png'
import ImageGallery from "react-image-gallery";
import invictus from '../../images/invictus.png'
import rp from '../../images/rp.png'
import samalResort from '../../images/samal-resort.jpg'
import stadiumCentral from '../../images/stadium-central.jpg'
import oiqaragai from '../../images/oiqaragai.jpg'
import kolsay from '../../images/kolsay.png'
import khorgos from '../../images/khorgos.png'
import ramstoreAllIn from '../../images/ramstoreallin.png'
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";
import CollectionsIcon from '@mui/icons-material/Collections';
import InfoIcon from '@mui/icons-material/Info';
import rubai from '../../images/rubai.jpg'
import ramstore from '../../images/ramstore.jpg'
import sadu from '../../images/sadu.jpg'
import samal from '../../images/samal.jpg'
import stad from '../../images/stad.jpg'
import lk from '../../images/lk.jpg'
import CloseIcon from '@mui/icons-material/Close';
import Carousel from "nuka-carousel"
import almaty from '../../images/almaty.jpg'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import NewsContainer from "../News/NewsContainer";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import Language from "../Language/Language";
import LanguageContainer from "../Language/LanguageContainer";
import {ruValues} from "../../localization/ru";
import {kzValues} from "../../localization/kz";
import {enValues} from "../../localization/en";
import Footer from "../Footer/Footer";
import {Route, Routes} from "react-router-dom";
import UserInfoContainer from "../UserInfo/UserInfoContainer";
import AboutUsContainer from "../AboutUs/AboutUsContainer";
import {Clear, Close} from "@mui/icons-material";
import NewsItemContainer from "../NewsItem/NewsItemContainer";
import FaqContainer from "../Faq/FaqContainer";
import WhyChooseUsContainer from "../WhyChooseUs/WhyChooseUsContainer";
import PartnersContainer from "../Partners/PartnersContainer";
import GalleryContainer from "../Gallery/GalleryContainer";
import OurServicesContainer from "../OurServices/OurServicesContainer";
import DownloadContainer from "../Download/DownloadContainer";
import MapContainer from "../ChargersMap/ChargersMapContainer";
import ChargersMapContainer from "../ChargersMap/ChargersMapContainer";
import SendRequestDialogContainer from "../SendRequestDialog/SendRequestDialogContainer";
import StatsContainer from "../Stats/StatsContainer";
import ChatwootWidget from "../ChatWoot/ChatWoot";
import ProductsContainer from "../Products/ProductsContainer";
import BecomePartnerContainer from "../BecomePartner/BecomePartnerContainer";

const getLanguageValues = (language) => {
    switch (language) {
        case 'ru': return ruValues
        case 'kz': return kzValues
        case 'en': return enValues
    }
}

const Main = ({ language }) => {


    const [languageValues, setLanguageValues] = useState(getLanguageValues(language))

    useEffect(() => {
        setLanguageValues(getLanguageValues(language))
    }, [language])

    const [sendRequest, setSendRequest] = useState(false)
    const handleOpenSendRequest = () => {
        setSendRequest(true)
    }
    const handleCloseSendRequest = () => {
        setSendRequest(false)
    }

    const [shashu, setShashu] = useState(false)

    const handleCloseShashu = () => {
        setShashu(false)
    }

    useEffect(() => {
        setShashu(true)
    }, [])

    return (
        <>
            <ShashuDialog open={shashu} handleClose={handleCloseShashu}/>
            <HeaderContainer languageValues={languageValues}/>
            <Box mt={{ sm: 16, xs: 14 }}>
                <Routes>
                    <Route path="/" element={<ContentContainer handleOpenSendRequest={handleOpenSendRequest} languageValues={languageValues} language={language}/>} />
                    <Route path="/products" element={<ProductsContainer handleOpenSendRequest={handleOpenSendRequest} languageValues={languageValues} language={language}/>} />
                    <Route path="/users" element={<UserInfoContainer languageValues={languageValues} language={language}/>} />
                    <Route path="/about" element={<AboutUsContainer languageValues={languageValues} language={language}/>} />
                    <Route path="/map" element={<ChargersMapContainer languageValues={languageValues} language={language}/>} />
                    <Route path="/partners-info" element={<BecomePartnerContainer languageValues={languageValues} language={language}/>} />
                    <Route exact path="/news/:id" element={<NewsItemContainer languageValues={languageValues} language={language}/>} />
                </Routes>
            </Box>
            <SendRequestDialogContainer open={sendRequest} handleClose={handleCloseSendRequest}/>
            <Footer handleOpenSendRequest={handleOpenSendRequest} languageValues={languageValues}/>
            <ChatwootWidget/>
        </>
    )
}

const ShashuDialog = ({ open, handleClose }) => {
    return (
        <Dialog fullWidth open={open} onClose={handleClose}>
            <Box display={'flex'} justifyContent={'flex-end'} sx={{ padding: '15px', paddingBottom: 0 }}>
                <IconButton onClick={handleClose}>
                    <Close/>
                </IconButton>
            </Box>
            <Box mb={3}>
                <Box sx={{

                }}>
                    <Box p={4} justifyContent={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize:
                                {
                                    xs: '20px',
                                    md: '22px'
                                }
                        }}>Уважаемые пользователи приложения <span style={{ color: 'green', fontWeight: 'bold' }}>EVS.KZ!</span></Typography>
                        <Box mb={2}></Box>
                        <Typography sx={{ fontSize:
                                {
                                    xs: '16px',
                                    md: '16px'
                                }
                        }}>Настоящим сообщаем об изменении тарифа на городских зарядных станциях компании, начиная с 01.11.2024г. с <strong>80тг/Е</strong>, на <strong>90тг/Е</strong>.</Typography>
                    </Box>
                    <Box p={4} pt={0} mb={2} justifyContent={'center'} alignItems={'center'}>
                        <Typography sx={{
                            fontSize:
                                {
                                    xs: '20px',
                                    md: '22px'
                                }
                        }}>Құрметті <span style={{color: 'green', fontWeight: 'bold'}}>EVS.KZ</span> қолданбасы пайдаланушылары!</Typography>
                        <Box mb={2}></Box>
                        <Typography sx={{ fontSize:
                                {
                                    xs: '16px',
                                    md: '16px'
                                }
                        }}>
                            Cіздерге 01.11.2024ж. бастап, компанияның қалалық зарядтау станцияларында тарифтің <strong>80тг/Е-ден</strong>, <strong>90тг/Е-ге</strong> өзгеретіні туралы хабарлаймыз.
                        </Typography>
                    </Box>
                </Box>

            </Box>
        </Dialog>
    )
}



const ContentContainer = ({ language, languageValues, handleOpenSendRequest }) => {

    return (
        <>
            <Box>
                {/*download*/}
                <DownloadContainer languageValues={languageValues}/>
                {/*our services*/}
                <OurServicesContainer handleOpenSendRequest={handleOpenSendRequest} languageValues={languageValues}/>
                {/*stats*/}
                <StatsContainer languageValues={languageValues}/>
                {/*gallery*/}
                <GalleryContainer languageValues={languageValues}/>
                {/*news*/}
                <NewsContainer languageValues={languageValues} language={language}/>
                {/*faq*/}
                <FaqContainer languageValues={languageValues} language={language}/>
                {/*partners*/}
                <PartnersContainer languageValues={languageValues}/>
                {/*whyus*/}
                <WhyChooseUsContainer handleOpenSendRequest={handleOpenSendRequest} languageValues={languageValues}/>
            </Box>
        </>

    )
}

export default Main;